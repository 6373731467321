import React from "react";


const Connectivity = ( {statistics } ) => {

  const centuries = statistics ?  statistics.centuries : [];
  const summary =  statistics ?  statistics.summary : false;

  const centuryRows = centuries.map((century, idx) => (
      <tr key={idx}>
        <td>{century.century == null ? 'Óþekkt öld' : century.century}</td>
        <td>{century.total.toLocaleString()} <sup>({century.total - century.previous.total})</sup></td>
        <td>{(century.total - century.bothParents - century.onlyMother - century.onlyFather).toLocaleString()} <sup>({(century.total - century.bothParents - century.onlyMother - century.onlyFather) - (century.previous.total - century.previous.bothParents - century.previous.onlyMother - century.previous.onlyFather)})</sup></td>
        <td>{century.bothParents.toLocaleString()} <sup>({century.bothParents - century.previous.bothParents})</sup></td>
        <td>{century.onlyMother.toLocaleString()} <sup>({century.onlyMother - century.previous.onlyMother})</sup></td>
        <td>{century.onlyFather.toLocaleString()} <sup>({century.onlyFather - century.previous.onlyFather})</sup></td>
        <td>{(century.connectedPercent * 100).toFixed(2).toLocaleString()}% <sup>({((century.connectedPercent - century.previous.connectedPercent) * 100).toFixed(2).toLocaleString()}%)</sup></td>  
      </tr>
  ));

  return (
    <div>
      <table className="ui large celled table">
          <thead>
              <tr>
                  <th>Öld</th>
                  <th>Fjöldi</th>
                  <th>Einstæðingar</th>
                  <th>Bæði foreldri</th>
                  <th>Einungis móðir</th>
                  <th>Einungis faðir</th>
                  <th>Tengihlutfall</th>
              </tr>
          </thead>
        <tbody>
          {centuryRows}
        </tbody>
        {summary !== false && 
          <tfoot>
              <tr>
                  <th><b>Samtals</b></th>
                  <th><b>{summary.total.toLocaleString()}</b> <sup>({summary.total - summary.previous.total})</sup></th>
                  <th><b>{(summary.total - summary.bothParents - summary.onlyMother - summary.onlyFather).toLocaleString()}</b>  <sup>({(summary.total - summary.bothParents - summary.onlyMother - summary.onlyFather) - (summary.previous.total - summary.previous.bothParents - summary.previous.onlyMother - summary.previous.onlyFather)})</sup></th>
                  <th><b>{summary.bothParents.toLocaleString()}</b> <sup>({summary.bothParents - summary.previous.bothParents})</sup></th>
                  <th><b>{summary.onlyMother.toLocaleString()}</b> <sup>({summary.onlyMother - summary.previous.onlyMother})</sup></th>
                  <th><b>{summary.onlyFather.toLocaleString()}</b> <sup>({summary.onlyFather - summary.previous.onlyFather})</sup></th>
                  <th><b>{(summary.connectedPercent * 100).toFixed(2).toLocaleString()}%</b> <sup>({((summary.connectedPercent - summary.previous.connectedPercent) * 100).toFixed(2).toLocaleString()}%)</sup></th>
              </tr>
          </tfoot>
        }
      </table>
    </div>
  );
}

export default Connectivity;
