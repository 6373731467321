import React from "react";
import PropTypes from "prop-types";
import { NavLink as Link } from "react-router-dom";

import EventDate from "./EventDate";
import PersonName from "./PersonName";


const PersonList =  ({
  persons, selectedUUIDs, toggleSelectionAction, linkPerson
}) => {

    let hasParents = false;

    for(let index = 0; index < persons.length; index++) {
      if (persons[index].mother || persons[index].father) {
        hasParents = true;
        break;
      }
    }

    const skipTags = ['A', 'INPUT'];

    const personRows = persons.map((person, idx) => (
        <React.Fragment key={idx}>
        <tr key={idx} onClick={(event) => { if (selectedUUIDs !== false && skipTags.indexOf(event.target.tagName) === -1 ) { toggleSelectionAction(person.uuid); } }}>
          {
            selectedUUIDs !== false &&
            <td>
            <input
              type="checkbox"
              checked={!!selectedUUIDs[person.uuid]}
              onChange={() => {toggleSelectionAction(person.uuid); }}
            />
          </td>
          }
          <td>
              {linkPerson ?  
                <Link to={`/person/${person.uuid}`}><PersonName name={person.name}/></Link>
                : <b><PersonName name={person.name}/></b>
              }
              
              <div style={{'fontStyle': 'italic'}}>{person.title}</div>
          </td>
          <td>            
            {
              linkPerson ?
                <EventDate
                eventDate={person.birth}
                render={date => ( <Link to={`/person/${person.uuid}`}>{date} <div>{person.birthPlace}</div></Link>) }
                />
                :
                <EventDate
                eventDate={person.birth}
                render={date => ( <>{date} <div>{person.birthPlace}</div></>) }
                />
            }
          </td>
          <td>
          {
              linkPerson ?
                <EventDate
                eventDate={person.death}
                render={date => ( <Link to={`/person/${person.uuid}`}>{date} <div>{person.birthPlace}</div></Link>) }
                />
                :
                <EventDate
                eventDate={person.death}
                render={date => ( <>{date} <div>{person.birthPlace}</div></>) }
                />
            }
          </td>
          {hasParents && 
              <td>
              {person.father && 
                  <>
                      <PersonName name={person.father.name}/>
                      <EventDate
                          eventDate={person.father.birth}
                          render={date => ( <span>, f. {date} {person.father.birthPlace}</span>) }
                          />
                  </>
              }
            </td>
          }
          {
            hasParents &&
            <td>
              {person.mother && 
                  <>
                      <PersonName name={person.mother.name}/>
                      <EventDate
                          eventDate={person.mother.birth}
                          render={date => ( <span>, f. {date} {person.mother.birthPlace}</span>) }
                          />
                  </>
              }
            </td>     
          }     
        </tr>
        </React.Fragment >
      ));
    
    return (
        <table className="ui table striped">
          <thead>
            <tr>
              {
                selectedUUIDs !== false &&
                <th></th>
              }
              <th width={hasParents ? '33%' : '50%'}>Nafn</th>
              <th width={hasParents ? '16%' : '25%'}>Fdags.</th>
              <th width={hasParents ? '16%' : '25%'}>Ddags.</th>
              {hasParents && <th width="16%">Faðir</th>}
              {hasParents && <th width="16%">Móðir</th>}
            </tr>
          </thead>
          <tbody>{personRows}</tbody>
        </table>
    );
};

PersonList.propTypes = {
    persons: PropTypes.array.isRequired,
    actions: PropTypes.array
  };
  

export default PersonList;