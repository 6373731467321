import React, { useReducer, useState} from "react";

import { getDisconnected } from "./Genealogy";
import PersonList from './PersonList';

import Dates from "./utils/Dates";

function personsReducer(currentPersons, addedPersons) {
  if (addedPersons.clear === true) {
    return [];
  }
  else {
    return [...currentPersons, ...addedPersons];
  }
}

const PersonDisconnected = () => {

  const [persons, addPersons] = useReducer(personsReducer, []);
  const [loading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [validPeriod, setValidPeriod] = useState(true);
  const [period, setPeriod] = useState('');

  function updateOffset(newOffset) {
    setOffset(newOffset);

    if (persons.length - newOffset <= 100) {
      let datePeriod = Dates.convertDateForAPI(period);

      getDisconnected(persons.length, datePeriod.date, persons => {
        addPersons(persons);
      });
    }
  }

  function validatePeriod(currentPeriod) {
    let dateValid = Dates.convertDateForAPI(currentPeriod);
    console.table(dateValid);

    setValidPeriod((currentPeriod === '' || dateValid.date !== null));
  }

  function performSearch() {
    setIsLoading(true);
    setOffset(0);
    addPersons({clear: true});
    
    let datePeriod = Dates.convertDateForAPI(period);

    getDisconnected(0, datePeriod.date, persons => {
      setIsLoading(false);
      addPersons(persons);
    });
  }

  let currentPersons = persons.slice(offset, offset + 50);

  return (
    <div>
        <h1>Einstæðingar</h1> 
        <form className="ui form" onSubmit={(event) => {performSearch(); event.preventDefault(); }}>          
          <div className="inline fields">
            <div className="one wide field">
              <h4>Tímabil:</h4>
            </div>
            <div  className={validPeriod ? "three wide field " : "three wide field error"}>
                <input 
                type="text" 
                name="period" 
                placeholder="Sláðu inn tímabil"
                value={period}
                onChange={(event) => {setPeriod(event.target.value); validatePeriod(event.target.value);}}
                />
            </div>
            <div className="eight wide field">
              <button 
                className="ui button" 
                type="button"
                disabled={validPeriod !== true}
                onClick={() => performSearch()}
              >Sýna</button>
            </div>
            <div className="four wide field">
            {persons.length > 0 && 
              <>
                    <button 
                    className="ui button" 
                    type="button"
                    disabled={offset <= 0}
                    onClick={() => updateOffset(Math.max(0, offset  - 50))}
                  >
                    ❮ Fyrri siða
                  </button>
                  &nbsp;
                  <button 
                    className="ui button" 
                    type="button"
                    onClick={() => updateOffset(offset + 50)}
                  >
                    Næsta síða ❯
                  </button>
              </>
            }
            </div>      
          </div>
        </form>
        { loading && <div className="ui active centered inline loader" />}
        { !loading && persons.length > 0 &&
          <PersonList 
            selectedUUIDs={false} 
            persons={currentPersons}
            toggleSelectionAction={false}
            linkPerson={true}
          />
      }
   
    </div>
  );
};

export default PersonDisconnected;