import React, { useState, useEffect } from "react";
import {NavLink as Link } from "react-router-dom";

import AsyncSelect from 'react-select/async';

import debounce from 'debounce-promise';

import { getPlaceInfo, updateHabitation, searchPerson, registerHabitation, unregisterHabitation } from './Genealogy';
import Dates from './utils/Dates';

import PersonName from './PersonName';
import EventDate from './EventDate';

const editInfoTemplate = {
  id: null,
  period: '',
  originalPeriod: '',
  valid: true,
  working: false
};

const addInfoTemplate = {
  period: '',
  personName: '',
  personUUID: null,
  personBirth: null,
  valid: true,
  working: false,
  counter: 0
};

const sortHabitations = (habitations) => {
  return habitations.sort((a, b) => { 
    if (a.startYear < b.startYear) {
      return -1;
    } else if (a.startYear > b.startYear) {
      return 1;
    } else {
      return ('' + a.placeName).localeCompare(b.placeName);
    }    
  });
}


const getPeople = async (inputValue) => {
  const datePart = Dates.convertDateForAPI(inputValue);
  const searchTerm = datePart.date === null ? inputValue : datePart.otherText;
  
  return searchPerson(searchTerm,  datePart.date, result => {
    return result;
  });
};



const loadPeople = debounce(getPeople, 500, { leading: false});

const PlaceView = (props) => {
  let periodEditRef = React.createRef();
  let periodAddRef = React.createRef();

  const placeUUID = props.match.params.uuid;
  const [placeInfo, setPlaceInfo] = useState(null);
  const [addInfo, setAddInfo] = useState({...addInfoTemplate});
  const [editInfo, setEditInfo] = useState({...editInfoTemplate});
  const [deleteID, setDeleteID] = useState([]);

  useEffect(() => {
      setPlaceInfo(null);

      getPlaceInfo(placeUUID, info => {
          setPlaceInfo(info);
        })
        .catch(function(error) {
          console.log(error);
        });
  
  }, [placeUUID]);


  useEffect(() => {
    if (editInfo.id !== null && periodEditRef.current) {      
      periodEditRef.current.focus();
    } else if (editInfo.id === null && periodAddRef.current) {
      periodAddRef.current.focus();
    }
  }, [editInfo.id, periodEditRef, periodAddRef]);

  const handlePersonChange = (selectedPerson) => {
    setAddInfo({...addInfo, personName: selectedPerson.name, personUUID: selectedPerson.uuid, personBirth: selectedPerson.birth});
  };


  const startEditHabitation = (habitation) => {
    setEditInfo({
      ...editInfo,
      id: habitation.habitatId,
      period: habitation.period.trim(),
      originalPeriod: habitation.period.trim()
    });
  };

  const cancelEditing = () => {
    setEditInfo({...editInfo, id: null});
  }

  const updateAddPeriod = (period) => {
    const habitationPeriod = Dates.validateHabitationPeriod(period);

    setAddInfo({...addInfo, period: period, valid: habitationPeriod !== false });
  }

  const updateEditPeriod = (period) => {
    const habitationPeriod = Dates.validateHabitationPeriod(period);

    setEditInfo({...editInfo, period: period, editValid: habitationPeriod !== false});
  }

  const removeHabitation = (id) => {

    setDeleteID(prevDeleteIDs => {
      return [...prevDeleteIDs, id];
    });
    
    unregisterHabitation(id, result => {
      setDeleteID(prevDeleteIDs => {
        return prevDeleteIDs.filter((value) => {
          return value !== id;
        }
      )});
      

      setPlaceInfo(prevPlaceInfo => {
        const inhabitants = prevPlaceInfo.inhabitants.filter((value) => {
          return value.habitatId !== id;
        });

        return {...prevPlaceInfo, inhabitants: sortHabitations(inhabitants)};
      });  
    });
  };

  const saveEditChanges = () => {
    if (editInfo.id === null || !editInfo.valid) { 
      return;
    }

    if (editInfo.period.trim() === editInfo.originalPeriod.trim()) {
      setEditInfo({...editInfoTemplate});
      return;
    }

    setEditInfo({...editInfo, working: true});

    const periodInfo = Dates.validateHabitationPeriod(editInfo.period);

    updateHabitation(editInfo.id, placeUUID, periodInfo, result => {
      let inhabitants = [...placeInfo.inhabitants];

      inhabitants.forEach((inhabitant, index) => {
        if (inhabitant.habitatId === editInfo.id) {
          inhabitants[index] = {
            ...inhabitants[index],
            period: editInfo.period,
            ...periodInfo
          }
        }
      });

      setEditInfo({...editInfoTemplate});
      setPlaceInfo({...placeInfo, inhabitants: sortHabitations(inhabitants)});
    });  
  };

  const addHabitation = () => {
    if (!addInfo.valid || addInfo.personUUID === null) { 
      return;
    }

    const periodInfo = Dates.validateHabitationPeriod(addInfo.period);
    setAddInfo({...addInfo, working: true});

    registerHabitation(addInfo.personUUID, placeUUID, periodInfo, result => {
      let inhabitants = [...placeInfo.inhabitants];

      inhabitants.push({
        habitatId: result.id,
        personUUID: addInfo.personUUID,
        personName: addInfo.personName,
        birth: addInfo.personBirth,
        period: addInfo.period,
        ...periodInfo
      });

      setAddInfo({...addInfoTemplate, counter: addInfo.counter + 1});
      setPlaceInfo({...placeInfo, inhabitants: sortHabitations(inhabitants)});
    });  
  };

  const performActionOnEnter = (event, action) => {
    if (event.key !== "Enter") {
        return;
    }
    event.stopPropagation();        

    if (action instanceof Function) {
        action();
    }
  }

  if (placeInfo === null) {
      return (<div className="ui section loading"/>);
  }

  const inhabitantRows = placeInfo.inhabitants.map((inhabitant, index) => {

    if (editInfo.id !== inhabitant.habitatId) {
      const beingDeleted = deleteID.includes(inhabitant.habitatId);

      return (
        <tr key={index}>
          <td width="120">
            <b onClick={() => startEditHabitation(inhabitant)}>{inhabitant.period}</b>
          </td>
          <td>
            <Link to={`/person/${inhabitant.personUUID}`}>
                <PersonName name={inhabitant.personName}/>
            </Link>
            <EventDate
                eventDate={inhabitant.birth}
                render={date => (
                    <span>
                    , f. {date}
                    </span>
                )}
            />
          </td>
          <td width="200" style={{"textAlign": "right"}}>
            <div className="ui small compact icon buttons">
              <button className={"ui basic button"  + (beingDeleted ? ' loading' : '')} title="Eyða ábúð" type="button" onClick={() => { if (!editInfo.working && !beingDeleted) removeHabitation(inhabitant.habitatId);} }><i className="ban icon" /></button>
            </div>
          </td>
        </tr>          
      );
    } else {
      return (
        <tr key={index}>
          <td width="120">
            <div className={editInfo.valid ? '' : 'field error'}>
              <input                 
                disabled={editInfo.working}
                type="text" 
                value={editInfo.period} 
                onChange={({target}) => { updateEditPeriod(target.value); }}
                ref={periodEditRef}
                onKeyDown={ (event ) => performActionOnEnter(event, saveEditChanges) }
              />
            </div>
          </td>
          <td>
            <Link to={`/person/${inhabitant.personUUID}`}>
                <PersonName name={inhabitant.personName}/>
            </Link>
            <EventDate
                eventDate={inhabitant.birth}
                render={date => (
                    <span>
                    , f. {date}
                    </span>
                )}
            />
          </td>
          <td width="200" style={{"textAlign": "right"}}>
            <div className="ui small compact icon buttons">
                <>
                  <button disabled={!editInfo.valid || editInfo.working} className={"ui basic button"  + (editInfo.working ? ' loading' : '')} title="Vista ábúð" type="button" onClick={() => saveEditChanges() } ><i className="save icon" /></button>
                  <button disabled={editInfo.working} className="ui basic button" title="Hætta við breytingar" type="button" onClick={() => cancelEditing()}><i className="delete icon" /></button>
                </>
            </div>
          </td>
        </tr>
      );
    }
  });

  return (
    <>
      <div className="ui header">
          <h1>
              {placeInfo.name}
              {placeInfo.parent_name && 
              <span>, <Link to={`/place/list/${placeInfo.parent_uuid}`}>{placeInfo.parent_name}</Link></span>                
              }
          </h1>
      </div>
      {placeInfo.text}
      <div className="ui segment">
          <h5 className="ui header left floated">
            <i className="home icon"/> Ábúð 
          </h5>        
          <form className="ui form" onSubmit={(event) => event.preventDefault() }>
            <table className="ui very basic table">
              <tbody>
                {inhabitantRows}      
                <tr>
                  <td width="120">
                    <div className={(addInfo.valid || addInfo.period.trim() === '')  ? '' : 'field error'}>
                      <input 
                        type="text" 
                        value={addInfo.period} 
                        onChange={({target}) => updateAddPeriod(target.value)}
                        ref={periodAddRef}
                        onKeyDown={ (event ) => performActionOnEnter(event, addHabitation) }
                        disabled={addInfo.working}
                      />
                    </div>
                  </td>
                  <td>
                    <AsyncSelect 
                      key={addInfo.counter}
                      loadOptions={loadPeople}
                      id="habitationPersonField" 
                      name="habitationPersonField" 
                      onChange={(selectedOption) => handlePersonChange(selectedOption)}
                      placeholder="Sláðu inn texta til að leita"
                      getOptionLabel={option => { return (
                        <>
                          {option.name}            
                          <EventDate
                          eventDate={option.birth}
                          render={date => (
                              <span>
                              , f. {date}
                              </span>
                          )}
                          />
                      </>); } }
                      getOptionValue={option => option.uuid}
                    />
                  </td>
                  <td width="200" style={{"textAlign": "right"}}>
                    <div className="ui small compact icon buttons">
                        <button 
                          disabled={addInfo.valid === false || addInfo.personUUID === null || addInfo.working} 
                          className={"ui basic button" + (addInfo.working ? ' loading' : '')} 
                          title="Vista ábúð" type="button" onClick={() => addHabitation() }>
                            <i className="save icon" />
                        </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
      </div>
    </>
  );
};

export default PlaceView;