import React from "react";
import { NavLink as Link } from "react-router-dom";
import PropTypes from "prop-types";
import PersonName from "./PersonName";

const HorizontalTree = ({ nodes, nodeWidth, nodeHeight }) => {
  let options = {
    margin: {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20
    },
    nodeSize: {
      width: nodeWidth,
      height: nodeHeight
    },
    nodeSeperation: {
      x: 40,
      y: 25
    }
  };

  let maxY = 0;

  nodes.forEach(node => {
    maxY = Math.max(maxY, node.y);
  });

  const nodeElements = nodes.map((node, index) => {
    let x =
      options.margin.left +
      node.x * (options.nodeSize.width + options.nodeSeperation.x);
    let y =
      options.margin.top +
      node.y * (options.nodeSize.height + options.nodeSeperation.y);

    let parentLink = null;

    if (node.parent !== null) {
      let cy =
        options.margin.top + node.parent.y * (options.nodeSize.height + 25);

      if (node.y === node.parent.y) {
        parentLink = <path d={`M0,${10}H${-options.nodeSeperation.x}`} />;
      } else {
        parentLink = (
          <path
            d={`M0,${10}H${-(options.nodeSeperation.x / 2)}V${cy - y + 10}`}
          />
        );
      }
    }

    return (
      <g transform={`translate(${x},${y})`} key={index}>
        <rect
          rx="5"
          ry="5"
          width={options.nodeSize.width}
          height={options.nodeSize.height}
        />
        <Link to={`/person/${node.data.uuid}`}>
          <text
            dx=".35em"
            x="10"
            y="12"
            fill="black"
            textAnchor="start"
            fontSize="0.7em"
            fontFamily="Lucida Console, Courier, monospace"
          >
            <PersonName name={node.data.name}/>
          </text>
        </Link>
        {parentLink}
      </g>
    );
  });

  return (
    <svg
      width="1000"
      height={Math.max(
        500,
        options.margin.top +
          options.margin.bottom +
          maxY * (options.nodeSize.height + options.nodeSeperation.y)
      )}
      className="horizontal-tree"
    >
      {nodeElements}
    </svg>
  );
};

HorizontalTree.propTypes = {
  nodes: PropTypes.array.isRequired,
  nodeWidth: PropTypes.number.isRequired,
  nodeHeight: PropTypes.number.isRequired
};

export default HorizontalTree;