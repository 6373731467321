import React from "react";
import { withRouter } from "react-router-dom";

class SearchBar extends React.Component {
  state = {
    searchValue: ""
  };

  handleSearchChange = e => {
    const value = e.target.value;

    this.setState({
      searchValue: value
    });
  };

  handleSearch = e => {
    const { searchValue } = this.state;

    e.preventDefault();

    if (searchValue.trim() === "") {
      return;
    }

    this.props.history.push(`/search/${this.state.searchValue}`);
  };

  render() {
    return (
      <div className="item right floated">
        <div className="ui action input">
          <form onSubmit={this.handleSearch}>
            <input
              className="search main-search"
              type="text"
              placeholder="Leita að einstakling"
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
              onFocus={event => event.target.select()}
            />
            <button
              className="ui button search-button"
              onClick={this.handleSearch}
            >
              Leita
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchBar);
