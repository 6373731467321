import React from "react";

const PersonStatistics = (props) => {
    let { statistics } = props;

    if (statistics === null) {
        statistics = [];
    }
    
    const statisticsRows = statistics.map((statistic, idx) => (
        <tr key={idx}>
          <td>{statistic.date}</td>
          <td>{(statistic.men+statistic.women+statistic.unknown+statistic.ignored).toLocaleString()}</td>
          <td>{(statistic.men).toLocaleString()}</td>
          <td>{(statistic.women).toLocaleString()}</td>
          <td>{(statistic.unknown).toLocaleString()}</td>
          <td>{(statistic.connectedPercent * 100).toFixed(2).toLocaleString()}%</td>  
          <td>{(statistic.ignored).toLocaleString()}</td>
        </tr>
    ));

  return (
    <div>
        <table className="ui large celled table">
            <thead>
                <tr>
                    <th>Dagsetning</th>
                    <th>Fjöldi</th>
                    <th>Karlar</th>
                    <th>Konur</th>
                    <th>Óþekkt kyn</th>
                    <th>Tengihlutfall</th>
                    <th>Utan tölfræði</th>
                </tr>
            </thead>
          <tbody>
            {statisticsRows}
          </tbody>
        </table>        
    </div>
  );
};

export default PersonStatistics;