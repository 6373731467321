import React, { useState, useEffect } from "react";
import {NavLink as Link } from "react-router-dom";

import { getPlaces } from './Genealogy';

const PlaceList = (props) => {
    const placeParentUUID = props.match.params.uuid ? props.match.params.uuid : null;
    const [places, setPlaces] = useState(null);

    useEffect(() => {
        const storageKey = 'place_' + (placeParentUUID ? placeParentUUID : '');
        let placeCache = localStorage.getItem(storageKey);
        placeCache = placeCache ? JSON.parse(placeCache) : null;
        window.scrollTo(0, 0);

        if (!placeCache) {
            setPlaces(null);

            getPlaces(placeParentUUID, foundPlaces => {
                placeCache = foundPlaces;
                localStorage.setItem(storageKey, JSON.stringify(placeCache));
        
                setPlaces(foundPlaces);
                window.scrollTo(0, 0);
            })
            .catch(function(error) {
                console.log(error);
            });    
        } else {
            setPlaces(placeCache);
        }
    }, [placeParentUUID]);

    if (places === null) {
        return (
          <section className="hero">
            <div className="hero-body">
              <div className="container">
                <div className="loader"/>
              </div>
            </div>
          </section>
        )
      }
  

    const placeRows = places.map((place, index) => (
        <tr key={index}>
          <td><Link to={`/place/${place.type === 3 ? 'view/' + place.uuid : 'list/' + place.uuid}`}>{place.municipality}</Link></td>
            <td><Link to={`/place/${place.type === 3 ? 'view/' + place.uuid : 'list/' + place.uuid}`}>{place.name}</Link></td>
        </tr>
    ));
  
  
    return (
    <div>
        <h1 className="ui header floated left">Bæjalisti</h1>          
        {placeParentUUID && 
            <Link
            to='/place/list'
            title="Til baka"
            className="ui icon basic button right floated"
          >
            <i className="angle left icon" />
            Til baka
          </Link>
        }
        <table className="ui large celled table">
            <thead>
                <tr>
                <th width="200">
                    <i className="home icon"/>
                    Númer
                </th>
                <th>
                    <i className="home icon"/>
                    Staður
                </th>
                </tr>
            </thead>
            <tbody>{placeRows}</tbody>
        </table>
    </div>
    );
};

export default PlaceList;