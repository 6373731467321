import React, { useEffect, useState} from "react";
import { NavLink as Link, useParams } from "react-router-dom";

import Genealogy from "./Genealogy";
import Dates from "./utils/Dates";
import PersonList from "./PersonList";
import PersonCombine from "./PersonCombine";

const PersonSearch = () => {
  let { term } = useParams();


  const [persons, setPersons] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [combining, setCombining] = useState(false);

  let selectedUUIDs = Object.keys(selected);

  useEffect(() => {
    if (term !== "") {
      let termDecoded = decodeURIComponent(term);
      const datePart = Dates.convertDateForAPI(termDecoded);
      const searchTerm =
        datePart.date === null ? termDecoded : datePart.otherText;

        setIsLoading(true);
        setPersons([]);
        setSelected({});
      Genealogy.searchPerson(searchTerm, datePart.date, persons => {
        setIsLoading(false);
        setPersons(persons);
        setSelected({});
      });
    } else {
      setIsLoading(false);
      setPersons([]);
      setSelected({});
    }
  }, [term]);

  function combineSuccess(combinedPerson) {

    delete combinedPerson['mother'];
    delete combinedPerson['father'];

    
    let otherPersonIndex = null;
    let updatedPersons = [...persons];
    let selectedUUIDs = Object.keys(selected);

    updatedPersons.forEach((person, index) => {
      if (person.uuid === combinedPerson['uuid']) {
        updatedPersons[index] = combinedPerson;
      } else if (selectedUUIDs.indexOf(person['uuid']) !== -1) {
        otherPersonIndex = index;
      }
    });

    if (otherPersonIndex !== null) {
      updatedPersons.splice(otherPersonIndex, 1);
    }

    setPersons(updatedPersons);
    setCombining(false);
    setSelected({});
  };

  function togglePersonSelected(uuid) {
    let updatedSelection = {...selected};

    if (updatedSelection[uuid]) {
      delete updatedSelection[uuid];
    } else {
      updatedSelection[uuid] = true;
    }

    setSelected(updatedSelection);
  };

  function renderResults() {
    return (
      <>
        <h1 className="ui header floated left">Leitarniðurstöður</h1>
        {selectedUUIDs.length === 2 && (
              <button
                className="ui icon basic button"
                title="Sameina" 
                onClick={() => setCombining(true)}
              >
                <i className="clone icon" /> Sameina
              </button>
          )}
        {selectedUUIDs.length > 100000 && (
              <Link
                to={`/review/compare/${selectedUUIDs.join('/')}`}
                className="ui icon basic button"
                title="Sameina"
              >
                <i className="columns icon" /> Bera saman
              </Link>
          )}
          <PersonList 
            persons={persons} 
            selectedUUIDs={selected}
            toggleSelectionAction={(uuid) => togglePersonSelected(uuid)}
            linkPerson={true}
          />
      </>
    );
  };

  return  (
    <div className="ui segment">
      {!combining && renderResults()}
      {combining && 
        <PersonCombine 
          personUUID={selectedUUIDs[0]} 
          otherPersonUUID={selectedUUIDs[1]}
          cancelAction={() => setCombining(false) }
          combineSuccessAction={(combinedPerson) => combineSuccess(combinedPerson) }
        />
      }
      {loading && (
        <div className="ui active centered inline loader" />
      )}
    </div>
  );
}

export default PersonSearch;
