import React, { useReducer, useState, useEffect } from "react";

import PersonCombine from './PersonCombine';
import PersonList from './PersonList';

import { getDuplicates, updateReviews } from "./Genealogy";
import Dates from "./utils/Dates";

function duplicateReducer(currentDuplicates, additionalDuplicates) {
  if (additionalDuplicates instanceof Object && additionalDuplicates.hasOwnProperty('clear')) {
    return [];
  }  
  else if (additionalDuplicates instanceof Object && additionalDuplicates.hasOwnProperty('remove')) {
    return currentDuplicates.slice(additionalDuplicates.remove);
  } else if (additionalDuplicates instanceof Object && additionalDuplicates.hasOwnProperty('update')) {
    let updatedDuplicates = [...currentDuplicates];

    updatedDuplicates[additionalDuplicates.update] = additionalDuplicates.data;

    return updatedDuplicates;
  } else {
      return [...currentDuplicates, ...additionalDuplicates];
  }
}

function workStatusReducer(currentStatus, newStatus) {
  
  return {...currentStatus, ...newStatus};
}

const PersonDuplicates = ( ) => {

  const [workStatus, updateWorkStatus] = useReducer(workStatusReducer, {
    'skippedItems': 0,
    'currentDuplicate': false,
    'selected': {},
    'loadingMore': false,
    'combining': false,
    'nothingFound': false,
    'total': false,
  });

  const [validPeriod, setValidPeriod] = useState(true);
  const [period, setPeriod] = useState('');


  const [possibleDuplicates, addDuplicates] = useReducer(duplicateReducer, []);

  useEffect(() => {
    getDuplicates( 0, null, foundDuplicates => {
        addDuplicates(foundDuplicates.persons);

        const nextCandidates = foundDuplicates.persons[0];
        let selections = {}

        if (Array.isArray(nextCandidates) && nextCandidates.length > 2) {
          selections[nextCandidates[0]['uuid']] = true;
          selections[nextCandidates[1]['uuid']] = true;      
        } else {
          selections = false;
        }

        if (foundDuplicates.total !== false) {
          updateWorkStatus({'currentDuplicate': 0, 'selected': selections, 'total': foundDuplicates.total});
        } else {
          updateWorkStatus({'currentDuplicate': 0, 'selected': selections});
        }
    })
    .catch(function(error) {
        console.log(error);
    });    
  }, []); 

  function combineCompleted(combinedPerson, mergedPersonUUID) {
    updateReviews([combinedPerson['uuid'], mergedPersonUUID], () => {}).catch(() => {});
    updateWorkStatus({combining: false, total: workStatus.total - 1});

    let nextSelections = mergePersons(combinedPerson);
    if (nextSelections === -1) {
      moveToNextDuplicate();
      return;
    }

    updateWorkStatus({selected: nextSelections});
  }

  function mergePersons(combinedPerson) {
    let currentDuplicates = possibleDuplicates[workStatus.currentDuplicate];
    if (currentDuplicates.length === 2) {
      return -1;      
    }

    let selectedPersons = workStatus.selected;
    const selectedUUIDs = Object.keys(selectedPersons);

    currentDuplicates = currentDuplicates.filter(
      person => { 
        return selectedUUIDs.indexOf(person.uuid) === -1 || person.uuid === combinedPerson['uuid']; 
      }
    );

    currentDuplicates.forEach((person, index) => { 
      if (person.uuid === combinedPerson['uuid']) { 
        currentDuplicates[index] = combinedPerson; 
      } 
    });

    addDuplicates({
      update: workStatus.currentDuplicate, 
      data: currentDuplicates
    });

    let nextSelections = {}

    if (Array.isArray(currentDuplicates) && currentDuplicates.length > 2) {
      nextSelections[currentDuplicates[0]['uuid']] = true;
      nextSelections[currentDuplicates[1]['uuid']] = true;      
    } else {
      nextSelections = false;
    }

    return nextSelections;
  }

  function togglePersonSelected(uuid) {
    const { selected } = workStatus;

    if (selected[uuid]) {
      delete selected[uuid];
    } else {
      selected[uuid] = true;
    }

    updateWorkStatus({ selected: selected });    
  }

  function moveToNextDuplicate(skipped) {
    let currentDuplicate = workStatus.currentDuplicate + 1;
    if (currentDuplicate >= possibleDuplicates.length) {
      return;
    }

    moveToDuplicate(currentDuplicate, skipped);
  }

  function moveToDuplicate(duplicate, skipped) {

    let duplicateNumber = duplicate;
    const skippedItems = skipped === true ? workStatus.skippedItems + 1 : workStatus.skippedItems;
    const nextCandidates = possibleDuplicates[duplicateNumber];
    let nextSelections = {}

    if (Array.isArray(nextCandidates) && nextCandidates.length > 2) {
      nextSelections[nextCandidates[0]['uuid']] = true;
      nextSelections[nextCandidates[1]['uuid']] = true;      
    } else {
      nextSelections = false;
    }

    let loadingMore = false;
    if (possibleDuplicates.length - duplicateNumber === 10 && workStatus.loadingMore !== true) {
      
      loadingMore = true;
    }

    if (duplicateNumber ===10) {
      duplicateNumber -= 10;
      addDuplicates({remove: 10});
    }

    updateWorkStatus({
      skippedItems: skippedItems,
      currentDuplicate: duplicateNumber,
      selected: nextSelections,
      loadingMore: loadingMore
    });

    if (loadingMore) { 
      getMoreDuplicates(skippedItems);
    }
  }

  function getMoreDuplicates(skippedItems) {
    let datePeriod = Dates.convertDateForAPI(period);

    getDuplicates( skippedItems, datePeriod.date, foundDuplicates => {
      addDuplicates(foundDuplicates.persons);

      if (foundDuplicates.total !== false) {
        updateWorkStatus({'loadingMore': false, 'total': foundDuplicates.total});
      } else {
        updateWorkStatus({'loadingMore': false});
      }
    })
    .catch(function(error) {
        console.log(error);
    });    
  }

  function validatePeriod(currentPeriod) {
    let dateValid = Dates.convertDateForAPI(currentPeriod);

    setValidPeriod((currentPeriod === '' || dateValid.date !== null));
  }

  function searchByPeriod() {

    updateWorkStatus({
      'skippedItems': 0,
      'currentDuplicate': false,
      'selected': {},
      'loadingMore': false,
      'combining': false,
    });

    addDuplicates({clear: true});
    
    let datePeriod = Dates.convertDateForAPI(period);
    
    getDuplicates( 0, datePeriod.date, foundDuplicates => {
      addDuplicates(foundDuplicates.persons);
     
      const nextCandidates = foundDuplicates.persons[0];
      let selections = {}

      if (Array.isArray(nextCandidates) && nextCandidates.length > 2) {
        selections[nextCandidates[0]['uuid']] = true;
        selections[nextCandidates[1]['uuid']] = true;      
      } else {
        selections = false;
      }

      if (foundDuplicates.total !== false) {
        updateWorkStatus({'currentDuplicate': 0, 'selected': selections, 'total': foundDuplicates.total});
      } else {
        updateWorkStatus({'currentDuplicate': 0, 'selected': selections});
      }
    })
    .catch(function(error) {
        console.log(error);
    });   
  }

  const validDuplicate = workStatus.currentDuplicate !== false 
                          && possibleDuplicates.length > workStatus.currentDuplicate;

  let selectedUUIDs = [];
  let duplicateUUIDS = [];
  
  if (validDuplicate && workStatus.selected !== false) {
    selectedUUIDs = Object.keys(workStatus.selected);
  } else if (validDuplicate) {
    selectedUUIDs = [
      possibleDuplicates[workStatus.currentDuplicate][0]['uuid'],
      possibleDuplicates[workStatus.currentDuplicate][1]['uuid'],
    ];
  }

  if (validDuplicate) {
    duplicateUUIDS = possibleDuplicates[workStatus.currentDuplicate].map((person, index) => {
      return person.uuid;
    })
  }

  const validSelection = validDuplicate && (workStatus.selected === false || selectedUUIDs.length === 2);

  return (
      <div>
        <h2>Möguleg tvískráning:</h2>
        {
          workStatus.combining &&
          <PersonCombine 
            personUUID={selectedUUIDs[0]} 
            otherPersonUUID={selectedUUIDs[1]}
            cancelAction={() => updateWorkStatus({combining: false}) }
            combineSuccessAction={(combinedPerson, mergedPersonUUID) => combineCompleted(combinedPerson, mergedPersonUUID)}
          />
        }
        {
            workStatus.combining === false &&
            <div className="ui form">
              <div className="inline fields">
                <div className="twelve wide field">
                    <button 
                      className="ui positive button" 
                      type="button"
                      disabled={!validSelection}
                      onClick={() => updateWorkStatus({combining: true})}
                    >
                      Sameina
                    </button>
                    <button 
                      className="ui button" 
                      type="button"
                      disabled={!validDuplicate}
                      onClick={() => {updateReviews(duplicateUUIDS, () => {}).catch(() => {}); updateWorkStatus({total: workStatus.total - 1}); moveToNextDuplicate();}}
                    >
                      Ekki tvískráning
                    </button>
                    <button 
                      className="ui button" 
                      type="button"
                      disabled={!validDuplicate}
                      onClick={() => moveToNextDuplicate(true)}
                    >
                      Skoða seinna
                    </button>
                </div>
                  <div className="one wide field">
                    <h4>Tímabil:</h4>
                  </div>
                  <div  className={validPeriod ? "two wide field " : "two wide field error"}>
                    <form onSubmit={(event) => {searchByPeriod(); event.preventDefault(); }}>
                      <input 
                      type="text" 
                      name="period" 
                      placeholder="Sláðu inn tímabil"
                      value={period}
                      onChange={(event) => {setPeriod(event.target.value); validatePeriod(event.target.value);}}
                      />
                    </form>
                  </div>
                  <div className="one wide field">
                    <button 
                      className="ui button" 
                      type="button"
                      disabled={validPeriod !== true}
                      onClick={() => searchByPeriod()}
                    >Sýna</button>
                  </div>
            </div>
              <div>
                  <b>Fjöldi mögulegra tvískráninga: </b> {workStatus.total !== false ? workStatus.total.toLocaleString() : "?"}
              </div>
              {validDuplicate && 
                <PersonList 
                  selectedUUIDs={workStatus.selected} 
                  persons={possibleDuplicates[workStatus.currentDuplicate]}
                  toggleSelectionAction={togglePersonSelected}
                />          
              }
        </div>
        }
        {workStatus.currentDuplicate === false && <div className="ui active centered inline loader" />}
      </div>
  );
}

export default PersonDuplicates;