import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import PersonName from "./PersonName";

const Worklist = ({worklist, togglePersonWorklist}) => {

  let history = useHistory();
  const [itemsSelected, setItemsSelected] = useState([]);

  const handleChecked = ({ target: { value } }) => {
    const newItemsSelected = [...itemsSelected];
    const personUUID = value;

    if (itemsSelected.indexOf(personUUID) === -1) {
      newItemsSelected.push(personUUID);
    } else {
      newItemsSelected.splice(newItemsSelected.indexOf(personUUID), 1);
    }

    setItemsSelected(newItemsSelected);
  };

  function redirectAncestor() {
    history.push(
      `/ancestor/${itemsSelected[0]}/${itemsSelected[1]}`
    );
  };

  function redirectCombine() {
    history.push(
      `/combine/${itemsSelected[0]}/${itemsSelected[1]}`
    );
  };

  const twoSelected = itemsSelected.length === 2;

  const personRows = Object.keys(worklist).map((personUUID, idx) => (
    <div className="item" key={idx}>
      <input
        type="checkbox"
        className="ui large right floated"
        value={personUUID}
        onChange={personUUID => handleChecked(personUUID)}
      />
      <i
        className={
          worklist[personUUID].sex === 1
            ? "male icon"
            : worklist[personUUID].sex === 2 ? "female icon" : ""
        }
      />
      <div className="content">
        <Link to={`/person/${worklist[personUUID].uuid}`}>
          <PersonName name={worklist[personUUID].name}/>
        </Link>
      </div>
    </div>
  ));

  return (
    <div className="ui segment">
      <h1>Vinnulisti</h1>
      <button
        className="ui labeled icon button"
        disabled={!twoSelected}
        onClick={redirectAncestor}
      >
        <i className="sitemap icon" /> Rekja saman
      </button>
      <button
        className="ui labeled icon button"
        disabled={!twoSelected}
        onClick={redirectCombine}
      >
        <i className="clone icon" /> Sameina
      </button>
      <div className="ui relaxed divided list">{personRows}</div>
    </div>
  );
} 

export default Worklist;