const fallbackTypeIndex = 0;

const types = [
  { id: "UNKNOWN", labels: ["Óþekkt", "Óþekkt", "Óþekkt"] },
  { id: "HAD_CHILD", labels: ["Áttu barn", "Barnsfaðir", "Barnsmóðir"] },
  {
    id: "HOUSEKEEPER",
    labels: ["Ráðsmaður/kona", "Ráðsmaður/Bústjóri", "Ráðskona/Bústýra"]
  },
  { id: "TRAILING_SPOUSE", labels: ["Fylgimaki", "Fylgimaki", "Fylgimaki"] },
  { id: "MARRIED", labels: ["Gift", "Gift", "Gift"] },
  { id: "PARTNER", labels: ["Sambúð", "Sambúð", "Sambúð"] },
  { id: "ENGAGED", labels: ["Trúlofuð", "Unnusti", "Unnusta"] }
];

function getTypesForSex(sex) {
  sex = parseInt(sex, 10);

  const labelIndex = sex > 0 && sex < 3 ? sex : 0;

  return types.map(type => {
    return { id: type.id, label: type.labels[labelIndex] };
  });
}

function getTypeLabel(type, sex) {
  sex = parseInt(sex, 10);

  const typeID = type.toUpperCase();
  const labelIndex = sex > 0 && sex < 3 ? sex : 0;

  const typeEntry = types.find(element => {
    return element.id === typeID;
  });

  return typeEntry
    ? typeEntry.labels[labelIndex]
    : types[fallbackTypeIndex].labels[labelIndex];
}

const SpouseTypes = { getTypesForSex, getTypeLabel };

export default SpouseTypes;
