import Daughters from './Daughters';

function autoCompleteDaughter(fullName) {
    const nameParts = fullName.split(" ");

    if (nameParts.length < 2) {
        return fullName;
    }

    const lastName = nameParts[nameParts.length -1].toLowerCase();

    if (lastName in Daughters) {
        return fullName + "óttir";
    } else {
        return fullName;
    }
}

function autoFixName(fullName) {
    return autoCompleteDaughter(fullName.trim());
}

const Names = { autoFixName };
export default Names;