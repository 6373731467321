import React from "react";
import PropTypes from "prop-types";
import { NavLink as Link } from "react-router-dom";

import EventDate from "./EventDate";
import PersonSimple from "./PersonSimple";
import PersonName from "./PersonName";
import SexIcon from "./SexIcon";
import Spouse from "./Spouse";
import Genealogy from "./Genealogy";
import Dates from "./utils/Dates";

import PersonHabitation from "./PersonHabitation";
import PersonCombine from './PersonCombine';

class PersonDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPeople: {},
      combining: false
    };

    this.renderSiblings = this.renderSiblings.bind(this);
    this.togglePersonSelected = this.togglePersonSelected.bind(this);
    this.relationshipUpdated = this.relationshipUpdated.bind(this);
    this.unlinkPerson = this.unlinkPerson.bind(this);
    this.habitatChanged = this.habitatChanged.bind(this);
    this.setCombineMode = this.setCombineMode.bind(this);
    this.renderPersonDetails = this.renderPersonDetails.bind(this);
  }

  setCombineMode(mode) {
    this.setState({ combining: mode === true });
  }


  habitatChanged(updatedHabitaton) {
    const { id, placeUUID, period } = updatedHabitaton;
    const fullPlaceName = updatedHabitaton.placeName;

    let placeName = fullPlaceName;
    let parentPlaceName = '';
    let habitatFound = false;
    let periodInfo = Dates.validateHabitationPeriod(period);

    if (!periodInfo) {
      periodInfo = {};
    }

    const placeNameSeparatorIndex = fullPlaceName.indexOf(",");

    if (placeNameSeparatorIndex !== -1) {
      placeName = fullPlaceName.substring(0, placeNameSeparatorIndex).trim();
      parentPlaceName = fullPlaceName.substring(placeNameSeparatorIndex+1).trim();
    }

    let habitations = Array.isArray(this.props.person.habitations) ? [...this.props.person.habitations]: [];

    habitations.forEach((habitation, index) => {
      if (habitatFound) {
        return;
      }

      if (habitation.habitatId === id) {
        if (updatedHabitaton.removed) {
          habitations.splice(index, 1);

        } else {
          habitations[index] = {...habitation, placeName, placeUUID, parentPlaceName, period, ...periodInfo};
        }

        habitatFound = true;
      }
    })

    if (!habitatFound) {
      habitations.push({habitatId: id, placeName, placeUUID, parentPlaceName, period, ...periodInfo})
    }

    habitations = habitations.sort((a, b) => { 
      if (a.startYear < b.startYear) {
        return -1;
      } else if (a.startYear > b.startYear) {
        return 1;
      } else {
        return ('' + a.placeName).localeCompare(b.placeName);
      }
      
    });

    this.props.personUpdatedAction({ habitations: habitations });
  };


  unlinkPerson(otherPerson, unlinkType) {
    const { person } = this.props;

    if (window.confirm('Ertu viss um að þú viljir aftengja  "' + otherPerson.name +'"')) {
      Genealogy.unlinkPerson(person.uuid, otherPerson.uuid, unlinkType, result => {
        const updatedPerson = {};

        if (result.type === "PARENT") {
          if (person.father !== null && person.father.uuid === result.uuid) {
            updatedPerson.father = null;
          } 
          else if (person.mother !== null && person.mother.uuid === result.uuid) {
            updatedPerson.mother = null;
          }
        } 
        else if (result.type === "SPOUSE") {
          updatedPerson.spouses = [...person.spouses];

          for(let index = 0; index < updatedPerson.spouses.length; index++) {
            if (updatedPerson.spouses[index].uuid === result.uuid) {
              updatedPerson.spouses.splice(index, 1);
              break;
            }
          }
        }

        this.props.personUpdatedAction(updatedPerson);
      });
    }
  }

  relationshipUpdated(index, relationshipInfo) {
    const { spouses } = this.props.person;

    spouses[index].unionType = relationshipInfo.type;
    spouses[index].split = relationshipInfo.split;
    spouses[index].date = relationshipInfo.date;

    this.props.personUpdatedAction({ spouses: spouses });
  }

  togglePersonSelected(uuid) {
    const { selectedPeople } = this.state;

    if (selectedPeople[uuid]) {
      delete selectedPeople[uuid];
    } else {
      selectedPeople[uuid] = true;
    }

    this.setState({ selectedPeople: selectedPeople });
  }

  renderSiblings(type, siblings) {
    if (siblings.length <= 0) {
      return null;
    }

    const siblingRows = siblings.map((sibling, idx) => (
      <PersonSimple
        person={sibling}
        selectedPeople={this.state.selectedPeople}
        toggleSelection={this.togglePersonSelected}
        worklist={this.props.worklist}
        togglePersonWorklist={this.props.togglePersonWorklist}
        detailed={true}
        key={idx}
      />
    ));

    return (
      <div className="ui mini compact segment">
        <h5 className="ui header">
          <i className="users icon" />
          {type}
        </h5>
        {siblingRows}
      </div>
    );
  }

  renderPersonDetails(selectedUUIDs) {
    const { person, worklist, match } = this.props;
    const text = Array.isArray(person.text) ? person.text : [person.text];
    const references = Array.isArray(person.references)
      ? person.references
      : [];
    const father = person.father;
    const mother = person.mother;
    const personSex = parseInt(person.sex, 10);
    const habitation = Array.isArray(person.habitations)
      ? person.habitations.map((habitation, index) => {
        return { 
          id: habitation.habitatId, 
          placeName: habitation.placeName + (habitation.parentPlaceName ? ', ' + habitation.parentPlaceName : ''),
          placeUUID: habitation.placeUUID,
          period: habitation.period
        }
      })
      : [];

    const spouseRows = person.spouses.map((spouse, idx) => (
      <Spouse
        key={idx}
        person={person}
        spouseIndex={idx}
        spouse={spouse}
        selectedPeople={this.state.selectedPeople}
        toggleSelection={this.togglePersonSelected}
        worklist={this.props.worklist}
        relationshipUpdated={this.relationshipUpdated}
        togglePersonWorklist={this.props.togglePersonWorklist}
        unlinkPersonAction={this.unlinkPerson}
      />
    ));

    const textRows = text.map((text, idx) => <p key={idx}>{text}</p>);

    const referenceRows = references.map((reference, idx) => (
      <span key={idx}>{reference}, </span>
    ));

    return (
      <div>
        <div className="ui big icon buttons">
          <Link
            to={`${match.url}/edit`}
            title="Breyta"
            className="ui icon basic button"
          >
            <i className="edit icon" />
          </Link>
          <button
            title="Skrá maka"
            className="ui icon basic button"
            onClick={() => this.props.linkPersonAction("spouse")}
          >
            <i className="icons">
              <i className="users icon" />
              <i className="bottom right corner add mini fitted icon" />
            </i>
          </button>
          {(personSex === 1 || personSex === 2) && (
            <button
              title="Skrá barn"
              className="ui icon basic button"
              onClick={() => this.props.linkPersonAction("child")}
            >
              <i className="icons">
                <i className="child icon" />
                <i className="bottom right corner add mini fitted icon" />
              </i>
            </button>
          )}
          <Link
            to={`/person/${person.uuid}/familytree`}
            className="ui icon basic button"
            title="Ættartré"
          >
            <i className="sitemap icon" />
          </Link>
          <Link
            to={`/person/${person.uuid}/ancestors`}
            className="ui icon basic button"
            title="Framætt"
          >
            <i className="vertically flipped sitemap icon" />
          </Link>
          <button
            className="ui icon basic button"
            onClick={() => this.props.togglePersonWorklist(person)}
            title={
              !worklist[person.uuid]
                ? "Setja á vinnulista"
                : "Taka af vinnulista"
            }
          >
            <i
              className={
                "ui star icon " + (!worklist[person.uuid] ? "empty" : "")
              }
            />
          </button>
          <button
            className="ui icon basic button"
            onClick={() => this.props.togglePersonIgnoreInStats(person)}
            title={
              !person.ignoreInStats
                ? "Taka úr tölfræði"
                : "Hafa með í tölfræði"
            }
          >
            <i
              className={
                "ui icon " + (person.ignoreInStats ? "check circle outline" : "ban") + " icon"
              }
            />
          </button>
          {selectedUUIDs.length === 2 && (
            <button
              className="ui icon basic button"
              title="Sameina"
              type="button"
              onClick={() => this.setCombineMode(true)}
            >
              <i className="clone icon" />
            </button>
          )}
        </div>
          <div className="ui two column compact stretched grid">
            <div className="column">
              <div className="ui horizontal segment">
                <h5 className="ui header">
                  <i className="male icon" />
                  Faðir
                </h5>
                {father === null && (
                  <button
                    title="Skrá föður"
                    className="ui compact basic icon button right floated"
                    onClick={() => this.props.linkPersonAction("father")}
                  >
                    <i className="add icon" />
                    Skrá föður
                  </button>
                )}
                {father !== null && (
                  <div>
                    <div className="ui big icon buttons right floated">
                      <button
                        title="Afskrá föður"
                        className="ui compact basic icon button right floated"
                        onClick={() => this.unlinkPerson(father, 'PARENT')}
                      >
                        <i className="ban icon" />
                      </button>
                    </div>
                    <Link to={`/person/${father.uuid}`}>
                      <PersonName name={father.name}/>
                    </Link>
                    <EventDate
                      eventDate={father.birth}
                      render={date => (
                        <div>
                          f. {date} {father.birthPlace}
                        </div>
                      )}
                    />
                    <EventDate
                      eventDate={father.death}
                      render={date => (
                        <div>
                          d. {date} {father.deathPlace}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="column">
              <div className="ui horizontal segment">
                <h5 className="ui header">
                  <i className="female icon" />
                  Móðir
                </h5>
                {mother === null && (
                  <button
                    title="Skrá föður"
                    className="ui compact basic icon button right floated"
                    onClick={() => this.props.linkPersonAction("mother")}
                  >
                    <i className="add icon" />
                    Skrá móður
                  </button>
                )}
                {mother !== null && (
                  <div>
                    <div className="ui big icon buttons right floated">
                      <button
                        title="Afskrá móður"
                        className="ui compact basic icon button right floated"
                        onClick={() => this.unlinkPerson(mother, 'PARENT')}
                      >
                        <i className="ban icon" />
                      </button>
                    </div>
                    <Link to={`/person/${mother.uuid}`}>
                      <PersonName name={mother.name}/>
                    </Link>
                    <EventDate
                      eventDate={mother.birth}
                      render={date => (
                        <div>
                          f. {date} {mother.birthPlace}
                        </div>
                      )}
                    />
                    <EventDate
                      eventDate={mother.death}
                      render={date => (
                        <div>
                          d. {date} {mother.deathPlace}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ui compact stretched grid">
            <div className="nine wide column">
              <div className="ui big segment">
                <h1>
                  <SexIcon sex={personSex} />
                  <PersonName name={person.name}/>
                </h1>
                <EventDate
                  eventDate={person.birth}
                  render={date => (
                    <div>
                      f. {date} {person.birthPlace}
                    </div>
                  )}
                />
                <EventDate
                  eventDate={person.death}
                  render={date => (
                    <div>
                      d. {date} {person.deathPlace}
                    </div>
                  )}
                />
                <div>{person.title}</div>
                {person.pid &&
                <div className="pid">kt: {person.pid.substring(0,6) + '-' + person.pid.substring(6)}</div>
                }
              </div>
            </div>
            <div className="seven wide column">
              <div className="ui compact segments">
                {this.renderSiblings("Alsystkin", person.siblings.both)}
                {this.renderSiblings("Samfeðra", person.siblings.father)}
                {this.renderSiblings("Sammæðra", person.siblings.mother)}
              </div>
            </div>
          </div>
          <div className="ui segment">
            <h5 className="ui horizontal divider header">
              <i className="users icon" />
              Makar og börn
            </h5>
            {spouseRows}
          </div>
          {textRows.length > 0 && (
            <div className="ui padded segment">{textRows}</div>
          )}
          <PersonHabitation personUUID={person.uuid} habitation={habitation} habitationUpdatedAction={this.habitatChanged}/>
          {referenceRows.length > 0 && (
            <div className="ui segment">
              <b>Heimild{referenceRows.length > 1 ? "ir" : ""}:</b>&nbsp;
              {referenceRows}
            </div>
          )}
        </div>
    );
    
  }

  render() {
    const selectedUUIDs = Object.keys(this.state.selectedPeople);

    return (
      <>
        {!this.state.combining && this.renderPersonDetails(selectedUUIDs)}
        {this.state.combining && 
          <PersonCombine 
            personUUID={selectedUUIDs[0]} 
            otherPersonUUID={selectedUUIDs[1]}
            cancelAction={() => this.setCombineMode(false) }
            combineSuccessAction={(combinedPerson) => this.props.combineSuccessAction()}
          />
        }
      </>
    )
  }
}

PersonDetail.propTypes = {
  person: PropTypes.object.isRequired,
  worklist: PropTypes.object.isRequired,
  togglePersonWorklist: PropTypes.func.isRequired,
  togglePersonMark: PropTypes.func.isRequired,
  togglePersonIgnoreInStats: PropTypes.func.isRequired,
  linkPersonAction: PropTypes.func.isRequired,
  personUpdatedAction: PropTypes.func.isRequired,
  combineSuccessAction: PropTypes.func.isRequired
};

export default PersonDetail;