import React from "react";
import PropTypes from "prop-types";

import Dates from "./utils/Dates";
import SpouseTypes from "./utils/SpouseTypes";

import Dialog from "./Dialog";
import Genealogy from "./Genealogy";

class RelationshipEdit extends React.Component {
  constructor(props) {
    super(props);

    const { spouse } = props;

    this.state = {
      relationship: {
        type: spouse.unionType,
        ended: spouse.split,
        date: Dates.parseEventDate(spouse.date)
      },
      working: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateRelationship = this.updateRelationship.bind(this);
  }

  updateRelationship() {
    const { relationship } = this.state;
    const { person, spouse } = this.props;
    const relationshipDate = Dates.convertDateForAPI(relationship.date);

    this.setState({ working: true });

    let relationshipInfo = {
      type: relationship.type,
      date: relationshipDate.date,
      split: relationship.ended
    };

    Genealogy.updateRelationship(
      person.uuid,
      spouse.uuid,
      relationshipInfo,
      result => {
        this.setState({ working: false });

        this.props.relationshipUpdated(result);
      }
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const { relationship } = this.state;

    relationship[name] = value;

    this.setState({
      relationship: relationship
    });
  }

  renderRelationOptions() {
    const spouseTypeRows = SpouseTypes.getTypesForSex(
      this.props.spouse.sex
    ).map((type, idx) => (
      <option value={type.id} key={idx}>
        {type.label}
      </option>
    ));

    return (
      <form
        className="ui form"
        onSubmit={event => this.handleSearchSubmit(event)}
        autoComplete="off"
      >
        <div className="required field">
          <label htmlFor="relationTypeField">Tegund sambands:</label>
          <select
            className="ui selection dropdown"
            name="type"
            id="relationTypeField"
            value={this.state.relationship.type}
            onChange={this.handleInputChange}
            autoFocus
          >
            {spouseTypeRows}
          </select>
        </div>
        <div className="two fields">
          <div className="field">
            <label htmlFor="relationDateField">Dags. sambands:</label>
            <input
              type="text"
              name="date"
              id="relationDateField"
              value={this.state.relationship.date}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="field">
            <label htmlFor="relationSplitField">Sambandi lokið?</label>
            <input
              type="checkbox"
              name="ended"
              id="relationSplitField"
              checked={this.state.relationship.ended}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <button
          type="button"
          tabIndex="2"
          className="ui positive button"
          onClick={this.updateRelationship}
        >
          Staðfesta
        </button>
      </form>
    );
  }

  render() {
    const { working } = this.state;

    return (
      <Dialog cancelAction={this.props.cancelEdit} escapeAction={ () => { if (!working) this.props.cancelEdit(); }}>
        {!working && this.renderRelationOptions()}
        {working && <div className="ui active centered inline loader" />}
      </Dialog>
    );
  }
}

RelationshipEdit.propTypes = {
  person: PropTypes.object.isRequired,
  spouse: PropTypes.object.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  relationshipUpdated: PropTypes.func.isRequired
};

export default RelationshipEdit;
