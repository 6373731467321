import React from "react";

const Dialog = ( {children, cancelAction, escapeAction} ) => {
    const backdropStyle = {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.66)",
        padding: 100,
        zIndex: 999
    };

    const contentStyle = {
        maxHeight: "620px",
        overflowY: "hide"
    };

    const handleKeyDown = (event) => {
        if (event.key !== "Escape") {
            return;
        }
        event.stopPropagation();        

        if (escapeAction instanceof Function) {
            escapeAction();
        } else {
            cancelAction();
        }
    }
    
    return (
        <div style={backdropStyle} onClick={event => { cancelAction(); }} >
            <div
                className="ui segment"
                tabIndex="0"
                style={contentStyle}
                onKeyDown={(event) => handleKeyDown(event)}
                onClick={event => { event.stopPropagation(); }}
            >
                {children}
            </div>
        </div>
    );

}

export default Dialog;
