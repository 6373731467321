import PropTypes from 'prop-types';
import Dates from './utils/Dates'

const EventDate = ({eventDate, render}) => { 

    let date = Dates.parseEventDate(eventDate);

    if (!date) {
        return (null);
    }

    if (eventDate.extras) {
        date = date + " " + eventDate.extras;
    }

    return render(date);
};

EventDate.propTypes = {
    eventDate: PropTypes.object,
    render: PropTypes.func.isRequired,
};

export default EventDate;