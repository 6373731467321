// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Q89cMc763",
    "aws_user_pools_web_client_id": "33qmomdrsa6fucfpo83qhbpacr",
    "oauth": {},

    Analytics: {
        disabled: true
    }
};


export default awsmobile;
