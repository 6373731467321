import React, { useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import SexIcon from "./SexIcon";
import EventDate from "./EventDate";
import PersonName from "./PersonName";

function useHookWithRefCallback() {
  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      node.focus();
    }
    
    // Save a reference to the node
    ref.current = node
  }, [])
  
  return [setRef]
}

const PersonSelectList = ({ persons, selected, selectionChange }) => {

  const [personList] = useHookWithRefCallback()

  if (persons.length <= 0) {
    return null;
  }
  const personRows = persons.map((person, idx) => (
    <div
      className=""
      key={idx}
      onClick={() => selectionChange(idx === selected ? -1 : idx, true)}
    >
      {idx === selected && (
        <span role="img" aria-label="selected">
          ✅
        </span>
      )}
      <SexIcon sex={person.sex} />
      <PersonName name={person.name}/>
      <EventDate
        eventDate={person.birth}
        render={date => (
          <span>
            {" "}
            , f. {date} {person.birthPlace}
          </span>
        )}
      />
      <EventDate
        eventDate={person.death}
        render={date => (
          <span>
            {" "}
            , d. {date} {person.deathPlace}
          </span>
        )}
      />
      {person.pid &&
        <span>, kt: {person.pid.substring(0,6) + '-' + person.pid.substring(6)}</span>
      }
    </div>
  ));

  const keyHandler = event => {
    let change = 0;

    if (event.key === "ArrowUp" && selected > 0 && persons.length > 0) {
      change = -1;
    } else if (event.key === "ArrowDown" && selected < persons.length - 1) {
      change = 1;
    }

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }

    if (change !== 0) {
      selectionChange(change);
    }
  };

  const listStyle = {
    maxHeight: "400px",
    overflowY: "scroll"
  };

  return (
    <div
      className="ui segment"
      style={listStyle}
      tabIndex="1"
      onKeyDown={keyHandler}
      autoFocus={true}
      ref={personList}
    >
      {personRows}
    </div>
  );

};

PersonSelectList.defaultProps = {
  selected: -1
};

PersonSelectList.propTypes = {
  persons: PropTypes.array.isRequired,
  selected: PropTypes.number,
  selectionChange: PropTypes.func.isRequired
};

export default PersonSelectList;
