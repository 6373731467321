import React from "react";
import { NavLink as Link } from "react-router-dom";
import PropTypes from "prop-types";
import PersonName from "./PersonName";
import SexIcon from "./SexIcon";
import EventDate from "./EventDate";

const PersonSimple = ({
  person,
  selectedPeople,
  toggleSelection,
  worklist,
  togglePersonWorklist,
  detailed,
  prefix
}) => {
  const inWorklist = worklist && worklist[person.uuid];

  return (
    <div className="person-simple">
      {prefix}
      <SexIcon sex={person.sex} />
      {selectedPeople && (
        <input
          type="checkbox"
          title="Velja einstakling"
          onChange={event => {
            toggleSelection(person.uuid);
          }}
          checked={!!selectedPeople[person.uuid]}
        />
      )}
      {worklist && (
        <button
          className="tiny compact ui icon basic button noborder"
          onClick={() => togglePersonWorklist(person)}
        >
          <i className={"ui star icon " + (!inWorklist ? "empty" : "")} />
        </button>
      )}
      <Link to={`/person/${person.uuid}`}>
        <PersonName name={person.name}/>
      </Link>
      {detailed && (
        <EventDate
          eventDate={person.birth}
          render={date => <span>, f. {date}</span>}
        />
      )}
      {detailed && (
        <EventDate
          eventDate={person.death}
          render={date => <span>, d. {date}</span>}
        />
      )}
    </div>
  );
};

PersonSimple.defaultProps = {
  detailed: false,
  worklist: {},
  prefix: ""
};

PersonSimple.propTypes = {
  person: PropTypes.object.isRequired,
  selectedPeople: PropTypes.object,
  toggleSelection: PropTypes.func,
  worklist: PropTypes.object,
  togglePersonWorklist: PropTypes.func,
  detailed: PropTypes.bool,
  prefix: PropTypes.string
};

export default PersonSimple;
