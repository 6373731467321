import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink as Link
} from "react-router-dom";

import "./App.css";
import logo from "./arctic-logo.png";
import StatisticsController from "./StatisticsController";
import PersonController from "./PersonController";
import PersonSearch from "./PersonSearch";
import SearchBar from "./SearchBar";
import Worklist from "./Worklist";
import Ancestor from "./Ancestor";
import PersonCombine from "./PersonCombine";
import DefaultReference from "./DefaultReference";
import PlaceController from "./PlaceController";
import ReviewController from "./ReviewController";

import { I18n, Amplify } from '@aws-amplify/core';
import awsconfig from './aws-exports';
import { withAuthenticator, SignIn, ConfirmSignIn, RequireNewPassword, VerifyContact, ForgotPassword, TOTPSetup, Loading } from 'aws-amplify-react/lib/Auth'; 

import strings from './strings';

//import LogRocket from 'logrocket';
//LogRocket.init('444cfv/sogusteinn');


Amplify.configure(awsconfig);


I18n.setLanguage('is');
I18n.putVocabularies(strings);


const App = () => {

  const [worklist, setWorklist] = useState({});

  useEffect(() => {
    let storedWorklist = JSON.parse(localStorage.getItem("worklist"));

    if (storedWorklist === null) {
      storedWorklist = {};
      localStorage.setItem("worklist", JSON.stringify({}));
    }
  
    setWorklist(storedWorklist);
  }, []);

  function togglePersonWorklist(person) {
    const worklist = JSON.parse(localStorage.getItem("worklist"));

    if (worklist[person.uuid]) {
      delete worklist[person.uuid];
    } else {
      worklist[person.uuid] = {
        uuid: person.uuid,
        name: person.name,
        sex: person.sex
      };
    }

    localStorage.setItem("worklist", JSON.stringify(worklist));
    setWorklist(worklist);
  };

  function updateWorklist(combinedPerson, mergedPersonUUID) {
    const worklist = JSON.parse(localStorage.getItem("worklist"));

    if (worklist[mergedPersonUUID]) {
      delete worklist[mergedPersonUUID];
    }

    if (worklist[combinedPerson['uuid']]) {
      worklist[combinedPerson['uuid']] = {
        uuid: combinedPerson['uuid'],
        name: combinedPerson['name'],
        sex: combinedPerson['sex']
      };
    }

    localStorage.setItem("worklist", JSON.stringify(worklist));
    setWorklist(worklist);

    return false;
  }

  return (
    <Router>
      <div>
        <div className="ui fixed menu">
          <div className="ui container">
            <Link exact to="/" className="ui header item" activeClassName="">
              <img className="logo" src={logo} alt="logo" /> Sögusteinn
            </Link>
            <Link to='/place' className="ui tiny header item">
              <i className="home icon"/>
              Bæjalisti
            </Link>
            <Link to='/review' className="ui tiny header item">
              <i className="list icon"/>
              Yfirferð
            </Link>
            <SearchBar />
            <div className="item right floated">
              <div className="ui action input">
                  <button
                    className="ui labeled icon button"
                    onClick={() => Amplify.Auth.signOut() }
                  >
                    <i className="sign out icon" /> Skrá sig út
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ui grid main">
          <div className="ui eleven wide column container">
            <Route path="/(statistics|)" component={StatisticsController} />
            <Route
              path="/person/:uuid"
              render={props => (
                <PersonController
                  {...props}
                  worklist={worklist}
                  togglePersonWorklist={togglePersonWorklist}
                />
              )}
            />
            <Route path="/ancestor/:person1/:person2" component={Ancestor} />
            <Route
              path="/combine/:person1/:person2"
              render={({match}) => (
                <div className="ui segment">
                  <PersonCombine 
                    personUUID={match.params.person1}
                    otherPersonUUID={match.params.person2}
                    cancelAction={false}
                    combineSuccessAction={(combinedPerson, mergedPersonUUID) => { return updateWorklist(combinedPerson, mergedPersonUUID); } }
                    />
                  </div>
              )}
            />
            <Route path="/search/:term" component={PersonSearch} />
            <Route
              path="/place"
              render={props => (
                <PlaceController
                  {...props}
                />
              )}
            />
            <Route
              path="/review"
              render={props => (
                <ReviewController
                  {...props}
                />
              )}
            />
          </div>
          <div className="ui five wide column container">
            <Worklist
              worklist={worklist}
              togglePersonWorklist={togglePersonWorklist}
            />
            <div className="ui section divider" />
            <DefaultReference />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default withAuthenticator(App, false, [<SignIn/>, <ConfirmSignIn/>, <RequireNewPassword/>, <VerifyContact/>, <ForgotPassword/>, <TOTPSetup/>, <Loading/>]);