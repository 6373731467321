import React from "react";
import PropTypes from "prop-types";
import AsyncCreatableSelect from 'react-select/async-creatable';

import Genealogy from "./Genealogy";
import Dates from "./utils/Dates";
import Names from "./utils/Names";
import SexIcon from "./SexIcon";

class PersonEdit extends React.Component {
  constructor(props) {
    super(props);

    const { person } = this.props;
    const dob = Dates.parseEventDate(person.birth) + (person.birth && person.birth.extras ? " " + person.birth.extras : "");
    const dod = Dates.parseEventDate(person.death);

    const defaultReference =
      "" + (localStorage.getItem("defaultReference") || "");

    const personDetails = {
      name:       person.name === null ? "" : person.name,
      sex:        person.sex === null ? 0 : person.sex,
      birth:      dob === null ? "" : dob,
      birthPlace: person.birthPlace === null ? "" : person.birthPlace,
      death:      dod === null ? "" : dod,
      deathPlace: person.deathPlace === null ? "" : person.deathPlace,
      title:      person.title === null ? "" : person.title,
      reference:  "",
      text:       Array.isArray(person.text) ? person.text.join("\n") : person.text
    };

    let originalPersonJSON = JSON.stringify(personDetails);

    personDetails.reference = defaultReference;

    this.state = {
      person: personDetails,
      originalPersonJSON: originalPersonJSON,
      saving: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBirthPlaceChange = this.handleBirthPlaceChange.bind(this);
    this.handleDeathPlaceChange = this.handleDeathPlaceChange.bind(this);
    
    this.validateChanges = this.validateChanges.bind(this);
    this.getBirthPlaces = this.getBirthPlaces.bind(this);
    this.getDeathPlaces = this.getDeathPlaces.bind(this);
    this.checkName = this.checkName.bind(this);
  }

  checkName() {
    const { person } = this.state;
    const updatedName = Names.autoFixName(person.name);

    if (updatedName !== person.name) {
      this.setState({person: {...person, name: updatedName} });
    }

  }

  validateChanges(event) {
    event.preventDefault();

    const { originalPersonJSON, saving } = this.state;
    let person = Object.assign({}, this.state.person);

    if (saving) {
      return;
    }

    const noChangesMade = JSON.stringify(person) === originalPersonJSON;

    if (noChangesMade) {
      // nothing to do, except for maybe message to user?
      return;
    }

    this.setState({
      saving: true
    });

    // Todo validate dates
    const birthDate = Dates.convertDateForAPI(person.birth, true);
    const deathDate = Dates.convertDateForAPI(person.death);
    person.birth = birthDate.date;
    person.death = deathDate.date;

    Genealogy.updatePerson(this.props.person.uuid, person, result => {
      this.setState({
        saving: false
      });

      this.props.personUpdatedAction(result);
    });
  }

  handleBirthPlaceChange(value) {
    const { person } = this.state;

    person.birthPlace = value ? value.place : "";

    this.setState({
      person: person
    });
  }

  handleDeathPlaceChange(value) {
    const { person } = this.state;

    person.deathPlace = value ? value.place : "";

    this.setState({
      person: person
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value =
      target.name === "sex" ? parseInt(target.value, 10) : target.value;
    const name = target.name;
    const { person } = this.state;

    person[name] = value;

    this.setState({
      person: person
    });
  }

  getBirthPlaces (input) {
    const { person } = this.state;
    
		if (!input) {
			return Promise.resolve([{place: person.birthPlace}]);
    }
    
    return Genealogy.searchPlace(input, result => {
      return result;
    })
	}

  getDeathPlaces (input) {
    const { person } = this.state;
    
		if (!input) {
			return Promise.resolve([{place: person.deathPlace}]);
    }
    
    return Genealogy.searchPlace(input, result => {
      return result;
    })
	}

  render() {
    const { person, originalPersonJSON, saving } = this.state;
    const noChangesMade = JSON.stringify(person) === originalPersonJSON;

    return (
      <div className="ui segment">
        <h1>Breyta einstakling</h1>
        <form className="ui big form" onSubmit={this.validateChanges} autoComplete="off">
          <fieldset style={{ border: "0px" }} disabled={saving}>
            <div className="required field">
              <label htmlFor="personNameField">Nafn:</label>
              <input
                type="text"
                name="name"
                id="personNameField"
                value={person.name}
                onChange={this.handleInputChange}
                onBlur={this.checkName}
              />
            </div>
            <div className="required field">
              <label>Kyn:</label>
              <div className="field">
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    name="sex"
                    value="1"
                    id="personSexMaleField"
                    checked={this.state.person.sex === 1}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="personSexMaleField">
                    <SexIcon sex={1} /> Karl
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    name="sex"
                    value="2"
                    id="personSexFemaleField"
                    checked={this.state.person.sex === 2}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="personSexFemaleField">
                    <SexIcon sex={2} /> Kona
                  </label>
                </div>
              </div>
            </div>
            <div className="two fields">
              <div className="required field">
                <label htmlFor="personDOBField">Fæðingardagur:</label>
                <input
                  type="text"
                  name="birth"
                  id="personDOBField"
                  value={person.birth}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="field">
                <label htmlFor="personPOBField">Staður:</label>
                <AsyncCreatableSelect 
                  id="personPOBField" 
                  name="birthPlace" 
                  placeholder="Sláðu inn texta til að leita"
                  defaultValue={{place: person.birthPlace}} 
                  onChange={(selectedOption) => this.handleBirthPlaceChange(selectedOption)} 
                  getOptionLabel={option => option.place}
                  getOptionValue={option => option.place}
                  loadOptions={this.getBirthPlaces} 
                  loadingPlaceholder="Leita ..."
                  noOptionsMessage={() => "Ekkert fannst"}
                  loadingMessage={() => "Leita ..."}
                  getNewOptionData={(inputValue, optionLabel) => { return { uuid: '', place: optionLabel, __isNew__: true};}}
                  formatCreateLabel={(inputValue) => `Nýr staður: ${inputValue}`}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="two fields">
              <div className="field">
                <label htmlFor="personDODField">Dánardagur:</label>
                <input
                  type="text"
                  name="death"
                  id="personDODField"
                  value={person.death}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="field">
                <label htmlFor="personPODField">Staður:</label>
                <AsyncCreatableSelect 
                  id="personPODField" 
                  name="deathPlace" 
                  placeholder="Sláðu inn texta til að leita"
                  defaultValue={{place: person.deathPlace}} 
                  onChange={(selectedOption) => this.handleDeathPlaceChange(selectedOption)} 
                  getOptionLabel={option => option.place}
                  getOptionValue={option => option.place}
                  loadOptions={this.getDeathPlaces} 
                  loadingPlaceholder="Leita ..."
                  noOptionsMessage={() => "Ekkert fannst"}
                  loadingMessage={() => "Leita ..."}
                  getNewOptionData={(inputValue, optionLabel) => { return { uuid: '', place: optionLabel, __isNew__: true};}}
                  formatCreateLabel={(inputValue) => `Nýr staður: ${inputValue}`}
                  isClearable={true}

                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="personNameField">Starfsheiti:</label>
              <input
                type="text"
                name="title"
                id="personTitleField"
                value={person.title}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="personTextField">Texti:</label>
              <textarea
                name="text"
                id="personTextField"
                value={person.text}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="personNameField">Heimild:</label>
              <input
                type="text"
                name="reference"
                id="personReferenceField"
                value={person.reference}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="ui buttons">
              <button
                className="ui button"
                type="button"
                onClick={event => {
                  event.preventDefault();
                  this.props.cancelPersonAction();
                }}
              >
                Hætta við
              </button>
              <div className="or" data-text="" />
              <button
                className={"ui positive button " + (saving ? "loading" : "")}
                disabled={noChangesMade || saving}
              >
                Breyta
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

PersonEdit.propTypes = {
  person: PropTypes.object.isRequired,
  cancelPersonAction: PropTypes.func.isRequired,
  personUpdatedAction: PropTypes.func.isRequired
};

export default PersonEdit;
