import React from "react";
import PropTypes from "prop-types";

import SpouseTypes from "./utils/SpouseTypes";

import Genealogy from "./Genealogy";
import Dialog from "./Dialog";
import PersonName from "./PersonName";
import PersonSelectList from "./PersonSelectList";
import EventDate from "./EventDate";
import Dates from "./utils/Dates";
import Names from "./utils/Names";

class LinkPerson extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkDetails: {
        personNameAndDOB: "",
        relationshipType: "MARRIED",
        relationshipEnded: false,
        relationshipDate: ''
      },
      registerNew: false,
      otherParent: null,
      candidateDetails: null,
      candidates: null,
      working: false,
      selectedCandidate: -1,
      selectedSpouse: -1,
      currentStep: "search"
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleCandidateSelected = this.handleCandidateSelected.bind(this);
    this.updateSelectedCandidate = this.updateSelectedCandidate.bind(this);
    this.updateSelectedSpouse = this.updateSelectedSpouse.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.performLinking = this.performLinking.bind(this);
  }

  handleEscape(event) {
    const { currentStep } = this.state;

    if (currentStep === "search" || currentStep === "finished") {
      this.props.cancelLink();
    } else if (
      currentStep === "child-options" ||
      currentStep === "parent-options" ||
      currentStep === "relationship-options"
    ) {
      this.setState({ currentStep: "select-candidate", working: false });
    } else if (currentStep === "select-candidate") {
      this.setState({ currentStep: "search", working: false });
    }
  }

  handleSearchSubmit(event) {
    event.preventDefault();

    const datePart = Dates.convertDateForAPI(
      this.state.linkDetails.personNameAndDOB
    );

    this.setState({ working: true, candidates: null, selectedCandidate: -1 });

    Genealogy.getLinkCandidates(
      this.props.person.uuid,
      this.props.linkType,
      datePart.otherText,
      datePart.date,
      candidates => {
        this.setState({
          candidates: candidates,
          working: false,
          currentStep: "select-candidate"
        });

        if (candidates.length === 1) {
          this.updateSelectedCandidate(0, true);
        }
      }
    );
  }

  performLinking() {
    const {
      linkDetails,
      selectedCandidate,
      candidates,
      otherParent,
      registerNew
    } = this.state;
    const { person, linkType } = this.props;
    const birthDate = Dates.convertDateForAPI(linkDetails.personNameAndDOB);
    const personName = birthDate.otherText;

    this.setState({ working: true });

    let linkOptions = {
      uuid:
        !registerNew && selectedCandidate !== -1
          ? candidates[selectedCandidate].uuid
          : null,
      name: personName,
      birth: birthDate.date,
      otherParent: otherParent
    };

    if (!registerNew && linkOptions.uuid === null) {
      console.log("Connection error");
      return;
    }

    if (linkType === "spouse") {
      let relationDate = Dates.convertDateForAPI(linkDetails.relationshipDate);

      linkOptions.relation = {
        type: linkDetails.relationshipType,
        split: linkDetails.relationshipEnded,
        date: relationDate.date
      };
    }
    Genealogy.linkPerson(person.uuid, linkType, linkOptions, result => {
      this.setState({ working: false, currentStep: "finished" });

      this.props.personUpdatedAction(result.person);
    });
  }

  handleCandidateSelected(event, createNew) {
    event.preventDefault();

    const { selectedCandidate, candidates, linkDetails } = this.state;
    const { linkType, person } = this.props;

    this.setState({
      candidateDetails: null,
      otherParent: null,
      selectedSpouse: -1,
      registerNew: createNew
    });

    if (createNew) {
      const newPersonDOB = Dates.convertDateForAPI(linkDetails.personNameAndDOB);
      const newPersonName = Names.autoFixName(newPersonDOB.otherText);

      this.setState({ linkDetails: {...linkDetails, personNameAndDOB: newPersonName + linkDetails.personNameAndDOB.substring(newPersonDOB.otherText.length)}});
    }

    if (linkType === "child" && !createNew) {
      this.setState({ working: true });

      Genealogy.getPerson(
        candidates[selectedCandidate].uuid,
        candidateRecord => {
          this.setState({
            candidateDetails: candidateRecord,
            working: false,
            currentStep: "child-options"
          });

          if (person["spouses"].length === 1) {
            this.updateSelectedSpouse(0, true);
          }
        }
      );
    } else if (linkType === "child") {
      this.setState({ working: false, currentStep: "child-options" });

      if (person["spouses"].length === 1) {
        this.updateSelectedSpouse(0, true);
      }
    } else if (linkType === "spouse") {
      this.setState({ working: false, currentStep: "relationship-options" });
    } else if (!createNew) {
      // linking to parent
      this.setState({ working: true });

      Genealogy.getPerson(
        candidates[selectedCandidate].uuid,
        candidateRecord => {
          this.setState({
            candidateDetails: candidateRecord,
            working: false,
            currentStep: "parent-options"
          });

          if (candidateRecord["spouses"].length === 1) {
            this.updateSelectedSpouse(0, true);
          }
        }
      );
    } else {
      this.setState({ working: false, currentStep: "parent-options" });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const { linkDetails } = this.state;

    linkDetails[name] = value;

    this.setState({
      linkDetails: linkDetails
    });
  }

  updateSelectedCandidate(change, setToIndex) {
    const { selectedCandidate } = this.state;

    this.setState({
      selectedCandidate:
        setToIndex === true ? change : selectedCandidate + change
    });
  }

  updateSelectedSpouse(change, setToIndex) {
    let { selectedSpouse, candidateDetails } = this.state;
    const { linkType } = this.props;

    selectedSpouse = setToIndex === true ? change : selectedSpouse + change;

    const spouses = (linkType === 'child' ? this.props.person.spouses : candidateDetails.spouses);

    const otherParent =
      selectedSpouse >= 0 &&
      selectedSpouse < spouses.length
        ? spouses[selectedSpouse]
        : null;

    this.setState({
      selectedSpouse: selectedSpouse,
      otherParent: otherParent === null ? null : otherParent.uuid
    });
  }

  renderCandidateList() {
    if (this.state.candidates === null) {
      return null;
    }

    const { selectedCandidate } = this.state;
    let candidates = Array.isArray(this.state.candidates)
      ? this.state.candidates
      : [];

    return (
      <form onSubmit={event => event.preventDefault()} autoComplete="off">
        <PersonSelectList
          persons={candidates}
          selected={selectedCandidate}
          selectionChange={this.updateSelectedCandidate}
        />
        <button
          type="button"
          tabIndex="2"
          className="ui blue button"
          disabled={candidates.length <= 0 || selectedCandidate === -1}
          onClick={event => {
            this.handleCandidateSelected(event, false);
          }}
        >
          Tengja
        </button>
        <button
          type="button"
          tabIndex="2"
          className="ui positive button"
          onClick={event => {
            this.handleCandidateSelected(event, true);
          }}
        >
          Nýskrá og tengja
        </button>
      </form>
    );
  }

  renderChildOptions() {
    const { candidateDetails, selectedSpouse } = this.state;
    const { person } = this.props;
    const personSex = parseInt(person.sex, 10);

    let otherParentSelection = null;

    if (
      candidateDetails !== null &&
      personSex === 1 &&
      candidateDetails.mother !== null
    ) {
      otherParentSelection = (
        <div className="ui section">
          <h4>Skráð móðir:</h4>
          <PersonName name={candidateDetails.mother.name}/>
          <EventDate
            eventDate={candidateDetails.mother.birth}
            render={date => <span>, f. {date}</span>}
          />
        </div>
      );
    } else if (
      candidateDetails !== null &&
      personSex === 2 &&
      candidateDetails.father !== null
    ) {
      otherParentSelection = (
        <div className="ui section">
          <h4>Skráður faðir:</h4>
          <PersonName name={candidateDetails.father.name}/>
          <EventDate
            eventDate={candidateDetails.father.birth}
            render={date => <span>, f. {date}</span>}
          />
        </div>
      );
    } else {
      otherParentSelection = (
        <PersonSelectList
          persons={person.spouses}
          selected={selectedSpouse}
          selectionChange={this.updateSelectedSpouse}
        />
      );
    }

    return (
      <form onSubmit={event => event.preventDefault()} autoComplete="off">
        {otherParentSelection}
        <br />
        <button
          type="button"
          tabIndex="2"
          className="ui positive button"
          onClick={this.performLinking}
        >
          Staðfesta
        </button>
      </form>
    );
  }

  renderParentOptions() {
    const { candidateDetails, selectedSpouse } = this.state;
    const { person } = this.props;

    let otherParentSelection = null;

    if (
      candidateDetails !== null &&
      this.props.linkType === "father" &&
      person.mother !== null
    ) {
      otherParentSelection = (
        <div className="ui section">
          <h4>Skráð móðir:</h4>
          <PersonName name={person.mother.name}/>
          <EventDate
            eventDate={person.mother.birth}
            render={date => <span>, f. {date}</span>}
          />
        </div>
      );
    } else if (
      candidateDetails !== null &&
      this.props.linkType === "mother" &&
      person.father !== null
    ) {
      otherParentSelection = (
        <div className="ui section">
          <h4>Skráður faðir:</h4>
          <PersonName name={person.father.name}/>
          <EventDate
            eventDate={person.father.birth}
            render={date => <span>, f. {date}</span>}
          />
        </div>
      );
    } else if (candidateDetails !== null) {
      otherParentSelection = (
        <PersonSelectList
          persons={candidateDetails.spouses}
          selected={selectedSpouse}
          selectionChange={this.updateSelectedSpouse}
        />
      );
    } else {
      otherParentSelection = <div>Engir mögulegir makar</div>;
    }

    return (
      <form onSubmit={event => event.preventDefault()} autoComplete="off">
        {otherParentSelection}
        <br />
        <button
          type="button"
          tabIndex="2"
          className="ui positive button"
          onClick={this.performLinking}
        >
          Staðfesta
        </button>
      </form>
    );
  }

  renderSearchForm() {
    const { working } = this.state;

    const linkTypes = {
      father: "föður",
      mother: "móður",
      spouse: "maka",
      child: "barns"
    };

    return (
      <form
        className="ui form"
        onSubmit={event => this.handleSearchSubmit(event)}
        autoComplete="off"
      >
        <div className=" field">
          <label htmlFor="personNameField">
            Nafn og fæðingardagur {linkTypes[this.props.linkType]}:
          </label>
          <input
            autoFocus
            type="text"
            name="personNameAndDOB"
            id="personNameField"
            autoComplete="off"
            value={this.state.linkDetails.personNameAndDOB}
            onChange={this.handleInputChange}
          />
        </div>
        <button
          type="submit"
          tabIndex="1"
          className={"ui positive button" + (working ? " loading" : "")}
        >
          Leita
        </button>
      </form>
    );
  }

  renderRelationOptions() {
    let sex = parseInt(this.props.person.sex, 10);
    sex = sex === 2 ? 1 : sex === 1 ? 2 : 0;

    const spouseTypeRows = SpouseTypes.getTypesForSex(sex).map((type, idx) => (
      <option value={type.id} key={idx}>
        {type.label}
      </option>
    ));

    const showDateField = this.state.linkDetails.relationshipType === 'MARRIED';

    return (
      <form
        className="ui form"
        onSubmit={event => this.handleSearchSubmit(event)}
        autoComplete="off"
      >
        <div className="two fields">
          <div className="required field">
            <label htmlFor="relationTypeField">Tegund sambands:</label>
            <select
              className="ui selection dropdown"
              name="relationshipType"
              id="relationTypeField"
              value={this.state.linkDetails.relationshipType}
              onChange={this.handleInputChange}
            >
              {spouseTypeRows}
            </select>
          </div>
          { showDateField && 
            <div className="field">
              <label htmlFor="relationDateField">Dags. hjónabands:</label>
              <input
                type="text"
                name="relationshipDate"
                id="relationDateField"
                autoFocus
                value={this.state.linkDetails.relationshipDate}
                onChange={this.handleInputChange}
              />
            </div>
          }
         
        </div>
          <div className="field">
            <label htmlFor="relationSplitField">Sambandi lokið?</label>
            <input
              type="checkbox"
              name="relationshipEnded"
              id="relationSplitField"
              value={this.state.linkDetails.relationshipEnded}
              onChange={this.handleInputChange}
            />
        </div>
        <button
          type="button"
          tabIndex="2"
          className="ui positive button"
          onClick={this.performLinking}
        >
          Staðfesta
        </button>
      </form>
    );
  }

  renderSteps() {
    const { currentStep } = this.state;
    const { linkType } = this.props;
    const personSex = parseInt(this.props.person.sex, 10);

    let completedSteps = 0;

    if (currentStep === "select-candidate") {
      completedSteps = 1;
    } else if (
      currentStep === "child-options" ||
      currentStep === "parent-options" ||
      currentStep === "relationship-options"
    ) {
      completedSteps = 2;
    } else if (currentStep === "finished") {
      completedSteps = 3;
    }

    if (linkType === "child") {
      return (
        <div className="ui mini three ordered steps">
          <div
            className={completedSteps === 0 ? "active step" : " completed step"}
          >
            <div className="title">Leita að barni</div>
          </div>
          <div
            className={
              completedSteps === 1
                ? "active step"
                : completedSteps > 1 ? " completed step" : "step"
            }
          >
            <div className="title">Velja barn</div>
          </div>
          <div
            className={
              completedSteps === 2
                ? "active step"
                : completedSteps > 2 ? " completed step" : "step"
            }
          >
            <div className="title">
              Staðfesta {personSex === 1 ? "móður" : "föður"}
            </div>
          </div>
        </div>
      );
    } else if (linkType === "spouse") {
      return (
        <div className="ui mini three ordered steps">
          <div
            className={completedSteps === 0 ? "active step" : "completed step"}
          >
            <div className="title">Leita að maka</div>
          </div>
          <div
            className={
              completedSteps === 1
                ? "active step"
                : completedSteps > 1 ? "completed step" : "step"
            }
          >
            <div className="title">Velja maka</div>
          </div>
          <div
            className={
              completedSteps === 2
                ? "active step"
                : completedSteps > 2 ? "completed step" : "step"
            }
          >
            <div className="title">Skrá nánari upplýsingar um samband </div>
          </div>
        </div>
      );
    } else {
      const searchPersonType = linkType === "mother" ? "móður" : "föður";

      return (
        <div className="ui mini three ordered steps">
          <div
            className={completedSteps === 0 ? "active step" : "completed step"}
          >
            <div className="title">Leita að {searchPersonType}</div>
          </div>
          <div
            className={
              completedSteps === 1
                ? "active step"
                : completedSteps > 1 ? " completed step" : "step "
            }
          >
            <div className="title">Velja {searchPersonType}</div>
          </div>
          <div
            className={
              completedSteps === 2
                ? "active step"
                : completedSteps > 2 ? " completed step" : "step"
            }
          >
            <div className="title">
              Staðfesta {linkType === "father" ? "móður" : "föður"}
            </div>
          </div>
        </div>
      );
    }
  }

  renderFinished() {
    if (this.state.working) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <div>
        <h3>Tenging hefur verið skráð</h3>
      </div>
    );
  }

  render() {
    const { currentStep } = this.state;

    return (
      <Dialog cancelAction={this.props.cancelLink} escapeAction={this.handleEscape}>
        {this.renderSteps()}
        {currentStep === "search" && this.renderSearchForm()}
        {currentStep === "select-candidate" && this.renderCandidateList()}
        {currentStep === "child-options" && this.renderChildOptions()}
        {currentStep === "parent-options" && this.renderParentOptions()}
        {currentStep === "relationship-options" &&
          this.renderRelationOptions()}
        {currentStep === "finished" && this.renderFinished()}
      </Dialog>
    );
  }
}

LinkPerson.propTypes = {
  person: PropTypes.object.isRequired,
  cancelLink: PropTypes.func.isRequired,
  personUpdatedAction: PropTypes.func.isRequired
};

export default LinkPerson;
