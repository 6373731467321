import React from "react";
import { Route, withRouter, NavLink as Link } from "react-router-dom";


import PersonDisconnected from './PersonDisconnected';
import PersonDuplicates from './PersonDuplicates';

const ReviewController = () => {

  return (
      <div className="ui padded segment">
        <Route
          exact
          path="/review"
          render={props => (
            <>
              <h1 className="ui header">Hér að neðan eru verkfæri sem hægt er að nota til yfirferðar</h1>
              <br/>
              <div className="ui cards">
              <Link className="ui raised card" to="/review/disconnected">
                  <div className="content">
                    <div className="header">Einstæðingar</div>
                  </div>
                  <div className="content">
                    <div className="description">
                      <p>Notað til að fá lista yfir einstæðinga í kerfinu, þ.e. þá einstaklinga sem hafa engin skráð foreldri.</p>
                    </div>
                  </div>
                </Link>
                <Link className="ui raised card" to="/review/duplicates">
                  <div className="content">
                    <div className="header">Mögulegar tvítekningar</div>
                  </div>
                  <div className="content">
                    <div className="description">
                      <p>Farið í gegnum mögulegar tvítekningar í kerfinu. Fyrir hvert tilvik er hægt að sameina, sleppa eða merkja að ekki sé um tvítekningu að ræða.</p>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        />          
        <Route 
          path="/review/disconnected"
          render={props => <PersonDisconnected {...props}/>}
        />
        <Route 
          path="/review/duplicates"
          exact
          render={props => <PersonDuplicates {...props}/>}
        />
    </div>
);
}

export default withRouter(ReviewController);