import React, { useState, useEffect } from "react";
import { Route,  NavLink as Link, useLocation } from "react-router-dom";

import Genealogy from "./Genealogy";

import Dashboard from "./Dashboard";
import Connectivity from "./Connectivity";
import PersonStatistics from './PersonStatistics'

const StatisticsController = () => {
  let location = useLocation();
  let statistics = JSON.parse(localStorage.getItem("statistics")) || { updated: 0, people: null, connectivity: null };

  const [peopleStats, setPeopleStats] = useState({ updated: statistics.updated, people: statistics.people });
  const [connectivityStats, setConnectivityStats] = useState({ updated: statistics.updated, connectivity: statistics.connectivity });

  useEffect(() => {
    Genealogy.getStatistics(stats => {
        let storedStats = JSON.parse(localStorage.getItem("statistics")) || { updated: 0, people: null, connectivity: null };

        localStorage.setItem("statistics", JSON.stringify({updated: Date.now() + 60000, people: stats, connectivity: storedStats.connectivity}));

        setPeopleStats({
          updated:  Date.now() + 60000, 
          people: stats
        });
    });

    Genealogy.getConnectivityStatistics(stats => {
        let storedStats = JSON.parse(localStorage.getItem("statistics")) || { updated: 0, people: null, connectivity: null };

        localStorage.setItem("statistics", JSON.stringify({updated: Date.now() + 60000, people: stats, connectivity: storedStats.connectivity}));

        setConnectivityStats({
          updated:  Date.now() + 60000, 
          connectivity: stats
        });
    });
  }, []);

  return (
    <div className="ui">
      <div className="ui top attached tabular menu">
        <div className={location.pathname === "/" ? "active item" : "item"}><Link to="/">Yfirlit</Link></div>
        <div className={location.pathname === "/statistics" ? "active item" : "item"}><Link to="/statistics">Tölfræði</Link></div>
        <div className={location.pathname === "/statistics/connectivity" ? "active item" : "item"}><Link to="/statistics/connectivity">Tengihlutfall</Link></div>
      </div>
      <div className="ui bottom attached active tab segment">
        <Route
          exact
          path="/"
          render={props => (
            <Dashboard statistics={peopleStats.people}/>
          )}
        />
        <Route
          exact
          path="/statistics"
          render={props => (
            <PersonStatistics statistics={peopleStats.people}/>
          )}
        />
        <Route
          exact
          path="/statistics/connectivity"
          render={props => (
            <Connectivity statistics={connectivityStats.connectivity} />
          )}
        />
      </div>
    </div>
  );
};

export default StatisticsController;
