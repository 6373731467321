import React from "react";

const Dashboard = ( {statistics} ) => {
  let currentStats  = {
    men: 0, women: 0, unknown: 0, connectedPercent: 0, ignored: 0
  };

  if (statistics !== null && statistics.length > 0) {
    currentStats = statistics[0]
  }
  
  return (
    <div className="ui five tiny statistics">
      <div className="statistic">
        <div className="value">
          {(currentStats.men+currentStats.women+currentStats.unknown+currentStats.ignored).toLocaleString()}
        </div>
        <div className="label">Einstaklingar</div>
      </div>
      <div className="statistic">
        <div className="value">
            {currentStats.men.toLocaleString()}
        </div>
        <div className="label">Karlar</div>
      </div>
      <div className="statistic">
        <div className="value">
            {currentStats.women.toLocaleString()}
        </div>
        <div className="label">Konur</div>
      </div>
      <div className="statistic">
        <div className="value">
            {currentStats.unknown.toLocaleString()}
        </div>
        <div className="label">Kyn óþekkt</div>
      </div>
      <div className="statistic">          
          <div className="value">
              {(currentStats.connectedPercent * 100).toFixed(2).toLocaleString()}%
          </div>
          <div className="label">Tengihlutfall</div>
      </div>
      <div className="statistic">
        <div className="value">
            {currentStats.ignored.toLocaleString()}
        </div>
        <div className="label">Utan tölfræði</div>
      </div>
    </div>
  );
}

export default Dashboard;
