export default function makeNodeTree(
  entries,
  childCallback,
  depth = 1,
  startY = 0,
  parent = null
) {
  if (entries.length <= 0) {
    return [];
  }

  let prevNode = null;
  let nodes = [];
  let nodeHeight = startY;

  entries.forEach(function(entry) {
    if (prevNode !== null) {
      nodeHeight = prevNode.maxY + 1;
    }

    let node = {
      data: {
        uuid: entry.uuid,
        name: entry.name,
        sex: entry.sex
      },
      depth: depth,
      x: depth - 1,
      y: nodeHeight,
      parent: parent,
      children: [],
      maxY: nodeHeight
    };

    node.children = makeNodeTree(
      childCallback(entry),
      childCallback,
      depth + 1,
      nodeHeight,
      node
    );

    nodes.push(node);

    if (node.children.length) {
      node.maxY = node.children[node.children.length - 1].maxY;
      nodes.push(...node.children);
    }

    prevNode = node;
    nodeHeight++;
  });

  return nodes;
}
