import React, { useState } from "react";
import { useLocalStorage } from './utils/Hooks';

const DefaultReference = () => {
  const [ defaultReferenceValue, setDefaultReferenceValue ] = useLocalStorage("defaultReference", "");
  const [ reference, setReference ] = useState(defaultReferenceValue);

  const referenceChanged = (value) => {
    setDefaultReferenceValue(value);
    setReference(value);
  };

  return (
    <form className="ui big form">
      <div className="field">
        <label htmlFor="defaultReferenceField">Festa heimild:</label>
        <input
          type="text"
          name="reference"
          id="defaultReferenceField"
          value={reference}
          onChange={( {target} ) => referenceChanged(target.value) }
        />
      </div>
    </form>
  );
};

export default DefaultReference;