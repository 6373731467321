import React, {useReducer, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { getPersonDifferencesForCombine, combinePersons } from "./Genealogy";
import PersonName from "./PersonName";
import EventDate from "./EventDate";

function processSelections(selections ) {
  const selectionsForAPI = {};

  Object.keys(selections).forEach((key) => {
    let selectionObj = selectionsForAPI;
    let keyParts = key.split("_");
    let valueKey = keyParts.pop(); 

    keyParts.forEach( keyPart => {
      if (!selectionObj.hasOwnProperty(keyPart)) {
        selectionObj[keyPart] = {};
      }

      selectionObj = selectionObj[keyPart];

    });

    if (selections[key] === "*" && !selectionObj.hasOwnProperty(valueKey)) {
      selectionObj[valueKey] = {};
    } else if (selections[key] !== "*") {
      selectionObj[valueKey] = selections[key];        
    }
  });

  return selectionsForAPI;
};

function objectCombineReducer(currentStatus, newStatus) {
  return {...currentStatus, ...newStatus};
}

const differenceItems = [
  { title: "Nafn", key: "name", value: value => value },
  { title: "Kennitala", key: "pid", value: value => value },
  {
    title: "Fæðingardagur",
    key: "birth",
    value: date => (<EventDate
      eventDate={date}
      render={date => date}
      />)
  },
  { title: "Fæðingarstaður", key: "birthPlace", value: value => value },
  {
    title: "Dánardagur",
    key: "death",
    value: date => (<EventDate
      eventDate={date}
      render={date => date}
      />)
  },
  { title: "Dánarstaður", key: "deathPlace", value: value => value },
  { title: "Starfsheiti", key: "title", value: value => value },
  {
    title: "Faðir",
    key: "father",
    value: father => (
      <React.Fragment>
        <PersonName name={father.name}/>
        <EventDate
          eventDate={father.birth}
          render={date => (<span>, f. {date}</span>)}
        />
      </React.Fragment>
    )
  },
  {
    title: "Móðir",
    key: "mother",
    value: mother => (
      <React.Fragment>
        <PersonName name={mother.name}/>
        <EventDate
          eventDate={mother.birth}
          render={date => (<span>, f. {date}</span>)}
        />
      </React.Fragment>
    )
  }
];

const PersonCombine = ({
  personUUID, 
  otherPersonUUID, 
  combineSuccessAction, 
  cancelAction
}) => {


  const [combineState, updateCombineState] = useReducer(objectCombineReducer, {
    personDifferences: null,
    selections: {},
    numSelectionsByKey: {},
    selectionsNeeded: 0,
    loading: true,
    combining: false
  });

  const { loading, combining } = combineState;

  let history = useHistory();

  useEffect(() => {
    if (personUUID === "" || otherPersonUUID === "") {
      updateCombineState({
        personDifferences: null,
        combining: false,
        selections: {},
        numSelectionsByKey: {},
        selectionsNeeded: 0,
        loading: false
      });

      return;
    }

    updateCombineState({
      loading: true,
      combining: false,
      selections: {},
      numSelectionsByKey: {},
      selectionsNeeded: 0,
      personDifferences: null
    });

    getPersonDifferencesForCombine(
      personUUID,
      otherPersonUUID,
      personDifferences => {
        
        if (personDifferences['differences'] && Object.keys(personDifferences['differences']).length === 0) {
          combinePersonsAction();
        } else {
          processPersonDifferences(personDifferences['differences']);
        }
      }
    );
  }, [personUUID, otherPersonUUID]);


  function handleInputChange (event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const { selections } = combineState;

    let selectionsNeeded = combineState.selectionsNeeded;
    let previousValue = selections[name]; 
    let namePrefix = name + "_";

    if (name.lastIndexOf("_") !== -1) {
      // Want to include the _ part
      namePrefix = name.substr(0, name.lastIndexOf("_") + 1);
    }

    selections[name] = value;

    selectionsNeeded = Object.keys(combineState.personDifferences).length;

    // Clear out tree of values if user is no longer combining an ancestor
    if (previousValue === "*" && value !== "*") {
      Object.keys(selections).forEach((key, index) => {
        if (key.indexOf(namePrefix) === 0) {
          delete selections[key];
        }
      });  
    }

    Object.keys(selections).forEach((key, index) => {
      if (selections[key] === "*" && combineState.numSelectionsByKey[key]) {
        selectionsNeeded += combineState.numSelectionsByKey[key];
      }
    });

    updateCombineState({
      selections: selections,
      selectionsNeeded: selectionsNeeded
    });
  }

  function renderComparison() {
    const numSelections = Object.keys(combineState.selections).length;

    const differenceRows = differenceItems.map((item, idx) =>
      renderDifferenceSelection(item.title, item.key, item.value, combineState.personDifferences, [
        personUUID, otherPersonUUID
      ], "")
    );

    return (
      <div className="ui form">
        <h4>Vinsamlegast veldu hvaða útgáfu af upplýsingunum þú vilt halda:</h4>
        {differenceRows}
        <div className="ui buttons">
          {cancelAction !== false && 
            <button className="ui button" type="button" onClick={() => cancelAction()}>
              Hætta við
            </button>
          }
          {cancelAction !== false && <div className="or" data-text="" />}
          <button
            className="ui positive button"
            type="button"
            disabled={combineState.selectionsNeeded !== numSelections}
            onClick={combinePersonsAction}
          >
            Sameina
          </button>
        </div>
      </div>
    );
  }


  function processPersonDifferences(personDifferences) {
    let numSelectionsByKey = {};

    let countDifferences = (differences, prefix = []) => {
      let numItems = 0;

      Object.keys(differences).forEach((key, index) => {
        if (typeof differences[key] === "object" && differences[key]['differences']) {
          let newPrefix = [...prefix, key];
          numSelectionsByKey[newPrefix.join('_')] = countDifferences(differences[key]['differences'], newPrefix);
        }

        numItems ++;
      });

      return numItems;
    };

    let numDifferences = countDifferences(personDifferences);

    updateCombineState({
      personDifferences: personDifferences,
      combining: false,
      selections: {},
      numSelectionsByKey: numSelectionsByKey,
      selectionsNeeded: numDifferences,
      loading: false
    });
  };

  function combinePersonsAction() {
    if (personUUID !== "" && otherPersonUUID !== "") {

      updateCombineState({ loading: false, combining: true });

      combinePersons(
        personUUID,
        otherPersonUUID,
        processSelections(combineState.selections),
        combinedPerson => {
          let mergedPersonUUID = combinedPerson['uuid'] === personUUID ? otherPersonUUID : personUUID;
          
          if (combineSuccessAction(combinedPerson, mergedPersonUUID) === false) {
            // If the callback returns false then that means we need to redirect
            // to the combined person.
            history.push(`/person/${combinedPerson['uuid']}`)
          }
        }
      );
    } else {
      updateCombineState({ combining: false });
    }
  };

  function renderDifferenceSelection(title, key, value, differences, uuids, selectionBase) {
    var selectionKey = (selectionBase === '' ? '' : selectionBase + '_') + key;

    if (!differences[key]) {
      return null;
    }

    var fieldOneName = selectionKey + "FieldOne";
    var fieldTwoName = selectionKey + "FieldTwo";

    var valueOne;
    var valueTwo;
    var canCombine = false;

    if ((key === 'mother' || key === 'father') && differences[key].hasOwnProperty('values')) {
      valueOne = differences[key]['values'][0];
      valueTwo = differences[key]['values'][1];
      canCombine = true;
    } else {
      valueOne = differences[key][0];
      valueTwo = differences[key][1];
    }

    return (
      <div className="grouped fields" key={selectionKey}>
        <label>{title}:</label>
        <div className="field">
          <div className="ui radio checkbox">
            <input
              type="radio"
              name={selectionKey}
              id={fieldOneName}
              value={uuids[0]}
              checked={
                combineState.selections[selectionKey] === uuids[0]
              }
              onChange={handleInputChange}
            />
            <label htmlFor={fieldOneName}>
              {value(valueOne)}
            </label>
          </div>
        </div>
        <div className="field">
          <div className="ui radio checkbox">
            <input
              type="radio"
              name={selectionKey}
              id={fieldTwoName}
              value={ uuids[1]}
              checked={
                combineState.selections[selectionKey] ===  uuids[1]
              }
              onChange={handleInputChange}
            />
            <label htmlFor={fieldTwoName}>
              {value(valueTwo)}
            </label>
          </div>
        </div>     
        { canCombine && 
          <div className="field">
            <div className="ui radio checkbox">
              <input
                type="radio"
                name={selectionKey}
                id={selectionKey + "Combine"}
                value="*"
                checked={
                  combineState.selections[selectionKey] === "*"
                }
                onChange={handleInputChange}
              />
              <label htmlFor={selectionKey + "Combine"}>
                Sameina {key === 'mother' ? 'mæður' : 'feður'}
              </label>
            </div>    
            {
              combineState.selections[selectionKey] === "*" && <div className="ui segment">
                {differenceItems.map((item, idx) =>
                  renderDifferenceSelection(item.title, item.key, item.value, differences[key]['differences'], [
                    valueOne['uuid'], valueTwo['uuid']
                  ], selectionBase === '' ? key : selectionBase + "_" + key)) }
                </div>
            }
          </div>
        }             
      </div>
    );
  };


  return (
    <>
      <h1>Sameina færslur</h1>
      {loading && (
        <div className="ui segment">
          <div className="ui active centered inline loader" />
        </div>
      )}
      {combining && (
        <div className="ui segment">
          <h3>Sameina færslur ...</h3>
          <div className="ui active centered inline loader" />
        </div>
      )}
      {!loading && !combining && renderComparison()}
    </>
  );
}

export default PersonCombine;
