import React, { useState } from "react";
import PropTypes from "prop-types";

import AsyncSelect from 'react-select/async';

import Dialog from './Dialog';
import { searchPlace, updateHabitation, registerHabitation } from "./Genealogy";
import Dates from "./utils/Dates";


const HabitatEditor = ( { habitat, cancelAction, updatedAction, personUUID }) => {

  const [ working, setWorking] = useState(false);
  const [ habitationPeriod, setHabitationPeriod ] = useState(habitat ? habitat.period : '');
  const [ habitationPlace, setHabitationPlace ] = useState({ uuid: habitat ? habitat.placeUUID : '', place: habitat ? habitat.placeName : '' } );

  const habitationPeriodValid = Dates.validateHabitationPeriod(habitationPeriod) !== false;
  const habitationPlaceValid = habitationPlace.uuid !== '';

  const handlePlaceChange = (selectedPlace) => {
    setHabitationPlace(selectedPlace);
  };

  const getPlaces = async (inputValue) => {
    return searchPlace(inputValue, result => {
      return result;
    });
  };

  return (
      <Dialog cancelAction={cancelAction}>
        <h2 className="ui header">{habitat === null ? "Skrá ábúð" : "Breyta ábúð"}</h2>
          <form
            className="ui form"
            onSubmit={event => event.preventDefault()}
            autoComplete="off"
          >
            <div className="two fields">
            <div className={"field" + (habitationPeriodValid || habitationPeriod === '' ? '' : ' error')}>
              <label htmlFor="habitationPeriodField">
                Tímabil:
              </label>
              <input
                type="text"
                name="habitationPeriod"
                id="habitationPeriod"
                autoComplete="off"
                value={habitationPeriod}
                onChange={(event) => setHabitationPeriod(event.target.value)}
                autoFocus
              />
            </div>
            <div className={"field" + (habitationPlaceValid ? '' : ' error')}>
              <label htmlFor="habitationPlaceField">
                Staður:
              </label>
              <AsyncSelect 
                defaultValue={habitationPlace}
                loadOptions={getPlaces}
                id="habitationPlaceField" 
                name="habitationPlaceField" 
                onChange={(selectedOption) => handlePlaceChange(selectedOption)}
                placeholder="Sláðu inn texta til að leita"
                getOptionLabel={option => option.place}
                getOptionValue={option => option.uuid}
                />
            </div>
          </div>
        <button
          type="button"
          tabIndex="2"
          className={"ui blue button" + (working ? " loading" : "")}
          disabled={!habitationPeriodValid || !habitationPlaceValid || working}
          onClick={event => {
            setWorking(true);

            if (habitat) {
              updateHabitation(habitat.id, habitationPlace.uuid, Dates.validateHabitationPeriod(habitationPeriod), result => {
                setWorking(false);
                updatedAction({ id: habitat.id, placeName: habitationPlace.place, placeUUID: habitationPlace.uuid, period: habitationPeriod});
              });  
            }
            else {
              registerHabitation(personUUID, habitationPlace.uuid, Dates.validateHabitationPeriod(habitationPeriod), result => {
                setWorking(false);
                updatedAction({ id: result.id, placeName: habitationPlace.place, placeUUID: habitationPlace.uuid, period: habitationPeriod});
              });  

            }

            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {habitat === null ? "Skrá ábúð" : "Breyta ábúð"}
        </button>
      </form>
      </Dialog>
  );
};


HabitatEditor.propTypes = {
  habitat: PropTypes.object,
  personUUID: PropTypes.string.isRequired,
  cancelAction: PropTypes.func.isRequired,
  updatedAction: PropTypes.func.isRequired
};

HabitatEditor.defaultProps = {
  habitat: null
}

export default HabitatEditor;
