import React from "react";
import { Route, withRouter } from "react-router-dom";
import { GlobalHotKeys } from 'react-hotkeys';

import Genealogy from "./Genealogy";
import PersonDetail from "./PersonDetail";
import PersonEdit from "./PersonEdit";
import LinkPerson from "./LinkPerson";
import FamilyTree from "./FamilyTree";
import AncestorTree from "./AncestorTree";

const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];

const keyMap = {
  selectFather: "v f",
  selectMother: "v m",
  selectChild: alphabet.map(function(letter, index) { return 'v b ' + letter; }),
  selectSpouse: alphabet.map(function(letter, index) { return 'v a ' + letter; })
};

class PersonController extends React.Component {

  state = {
    person: null,
    loading: false,
    linking: false,
    linkType: null
  };

  
  constructor(props) {
    super(props);

    this.reloadPerson = this.reloadPerson.bind(this);
    this.toggleMarked = this.toggleMarked.bind(this);
    this.toggleIgnoreInStats = this.toggleIgnoreInStats.bind(this);
    this.cancelPersonAction = this.cancelPersonAction.bind(this);
    this.personUpdated = this.personUpdated.bind(this);
    this.startLinkToPerson = this.startLinkToPerson.bind(this);
    this.cancelLink = this.cancelLink.bind(this);
    this.selectFather = this.selectFather.bind(this);
    this.selectMother = this.selectMother.bind(this);
    this.selectChild = this.selectChild.bind(this);
    this.selectSpouse = this.selectSpouse.bind(this);

    this.handlers = {
      selectFather: this.selectFather, 
      selectMother: this.selectMother,
      selectChild: (event) => { this.selectChild(event.key); },
      selectSpouse: (event) => { this.selectSpouse(event.key); },
    };  
  }

  reloadPerson() {
    this.loadPerson(this.props.match.params.uuid)
  }

  loadPerson = uuid => {
    if (uuid !== "") {
      this.setState({
        loading: true,
        linkType: null,
        linking: false,
        person: null
      });

      Genealogy.getPerson(uuid, person => {
        if (person["merged_into_uuid"]) {
          this.props.history.push(`/person/${person["merged_into_uuid"]}`);
        } else {
          this.setState({ person: person, loading: false });
        }
      });
    } else {
      this.setState({
        person: null,
        linkType: null,
        linking: false,
        loading: false
      });
    }
  };
  
  selectChild(key) {
    if (!this.state.person || !this.state.person.spouses) {
      return;
    }

    const { history } = this.props;

    this.state.person.spouses.forEach(function(spouse){
      spouse.children.forEach(function(child) {
        if (key === child.label) {
          history.push(`/person/${child.uuid}`);
        }
      })
    });
  }

  selectSpouse(key) {
    if (!this.state.person || !this.state.person.spouses) {
      return;
    }

    const spouseIndex = key.charCodeAt(0) - 'a'.charCodeAt(0);

    if (spouseIndex >= 0 && spouseIndex < this.state.person.spouses.length) {
      this.props.history.push(`/person/${this.state.person.spouses[spouseIndex].uuid}`);
    }
  }

  selectFather() {
    if (!this.state.person || !this.state.person.father) {
      return;
    }

    this.props.history.push(`/person/${this.state.person.father.uuid}`);
  }

  selectMother() {
    if (!this.state.person || !this.state.person.mother) {
      return;
    }

    this.props.history.push(`/person/${this.state.person.mother.uuid}`);
  }

  personUpdated(updates) {
    let updatedPerson = { ...this.state.person, ...updates };

    this.setState({ person: updatedPerson });

    this.props.history.push(`/person/${updatedPerson.uuid}`);
  }

  startLinkToPerson(linkType) {
    this.setState({ linking: true, linkType: linkType });
  }

  cancelLink() {
    this.setState({ linking: false, linkType: null });
  }

  cancelPersonAction() {
    const { person } = this.state;
    this.props.history.push(`/person/${person.uuid}`);
  }

  toggleMarked(person) {
    Genealogy.setPersonMark(person.uuid, !person.marked, markedResult => {
      person.marked = markedResult.marked;

      this.setState({ person: person });
    });
  }

  toggleIgnoreInStats(person) {
    Genealogy.setIgnoreInStats(person.uuid, !person.ignoreInStats, ignoreResult => {
      person.ignoreInStats = ignoreResult.ignore;

      this.setState({ person: person });
    });
  }

  componentWillMount() {
    this.loadPerson(this.props.match.params.uuid);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.uuid !== nextProps.match.params.uuid) {
      this.loadPerson(nextProps.match.params.uuid);
    }
  }

  render() {
    const { person, loading } = this.state;

    if (loading) {
      return (
        <div className="ui segment">
          <div className="ui active centered inline loader" />
        </div>
      );
    }

    return (
      <div className="ui segment">
        {this.state.linking && (
          <LinkPerson
            person={person}
            linkType={this.state.linkType}
            personUpdatedAction={this.personUpdated}
            cancelLink={this.cancelLink}
          />
        )}
        <Route
          exact
          path="/person/:uuid"
          render={props => (
              <>
                <GlobalHotKeys keyMap={keyMap} handlers={this.handlers}/>
                <PersonDetail
                  {...props}
                  person={person}
                  worklist={this.props.worklist}
                  personUpdatedAction={this.personUpdated}
                  linkPersonAction={this.startLinkToPerson}
                  addHabitationAction={this.startAddHabitation}
                  togglePersonWorklist={this.props.togglePersonWorklist}
                  togglePersonMark={this.toggleMarked}
                  togglePersonIgnoreInStats={this.toggleIgnoreInStats}
                  combineSuccessAction={this.reloadPerson}
                />
              </>
          )}
        />
        <Route
          exact
          path="/person/:uuid/edit"
          render={props => (
            <PersonEdit
              {...props}
              person={person}
              personUpdatedAction={this.personUpdated}
              cancelPersonAction={this.cancelPersonAction}
            />
          )}
        />
        <Route
          exact
          path="/person/:uuid/familytree"
          render={props => <FamilyTree match={this.props.match} />}
        />
        <Route
          exact
          path="/person/:uuid/ancestors"
          render={props => <AncestorTree match={this.props.match} />}
        />
      </div>
    );
  }
}

export default withRouter(PersonController);
