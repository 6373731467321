import React from "react";
import PropTypes from "prop-types";

const SexIcon = ({ sex }) => {
  let sexCode = parseInt(sex, 10);
  let iconClass = "genderless icon";

  if (sexCode === 1) {
    iconClass = "male icon";
  } else if (sexCode === 2) {
    iconClass = "female icon";
  }

  return <i className={iconClass} />;
};

SexIcon.propTypes = {
  sex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SexIcon;
