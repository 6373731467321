
import React from "react";
import { Route } from "react-router-dom";

import PlaceList from './PlaceList';
import PlaceView from './PlaceView';

const PlaceController = () => {

    return (
      <div className="ui segment">
        <Route
          exact
          path="/place/view/:uuid"
          render={props => (
            <PlaceView
              {...props}
            />
          )}
        />  
        <Route    
          exact        
          path={["/place/list/:uuid?", "/place"]}
          render={props => (
            <PlaceList {...props}/>
          )}
          />
      </div>
    );
}

export default PlaceController;