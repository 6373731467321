import React from "react";
import { NavLink as Link } from "react-router-dom";

import Genealogy from "./Genealogy";
import makeNodeTree from "./utils/renderTree";
import HorizontalTree from "./HorizontalTree";

class FamilyTree extends React.Component {
  state = {
    familyTree: null,
    loading: false
  };

  loadFamilyTree = uuid => {
    if (uuid !== "") {
      this.setState({ loading: true });
      Genealogy.getFamilyTree(uuid, familyTree => {
        this.setState({ familyTree: familyTree, loading: false });
      });
    } else {
      this.setState({ familyTree: null, loading: false });
    }
  };

  componentWillMount() {
    this.loadFamilyTree(this.props.match.params.uuid);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.uuid !== nextProps.match.params.uuid) {
      this.loadFamilyTree(nextProps.match.params.uuid);
    }
  }

  render() {
    const { familyTree } = this.state;

    let nodes = makeNodeTree(familyTree !== null ? [familyTree] : [], item => {
      return item.children;
    });

    return (
      <div className="ui segments">
        <div className="ui padded segment">
          <h1 className="ui header floated left">Ættartré</h1>
          <Link
            to={`/person/${this.props.match.params.uuid}`}
            title="Til baka"
            className="ui icon basic button right floated"
          >
            <i className="angle left icon" />
            Til baka
          </Link>
          <div className="familytree">
            <HorizontalTree nodes={nodes} nodeWidth={120} nodeHeight={20} />
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyTree;
