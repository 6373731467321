import React from "react";
import { NavLink as Link } from "react-router-dom";
import Genealogy from "./Genealogy";
import SexIcon from "./SexIcon";
import PersonName from "./PersonName";

class Ancestor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commonAncestors: null,
      selectedAncestor: -1,
      loading: true
    };

    this.updateSelection = this.updateSelection.bind(this);
  }

  updateSelection(event) {
    this.setState({ selectedAncestor: parseInt(event.target.value, 10) });
  }

  loadCommonAncestor = (person1, person2) => {
    if (person1 !== "" && person2 !== "") {
      this.setState({ loading: true, selectedAncestor: -1 });

      Genealogy.getCommonAncestor(person1, person2, commonAncestors => {
        this.setState({
          commonAncestors: commonAncestors,
          loading: false,
          selectedAncestor: commonAncestors.length > 0 ? 0 : -1
        });
      });
    } else {
      this.setState({
        commonAncestors: [],
        selectedAncestor: -1,
        loading: false
      });
    }
  };

  componentWillMount() {
    this.loadCommonAncestor(
      this.props.match.params.person1,
      this.props.match.params.person2
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.person1 !== nextProps.match.params.person1 &&
      this.props.match.params.person2 !== nextProps.match.params.person2
    ) {
      this.loadCommonAncestor(
        nextProps.match.params.person1,
        nextProps.match.params.person2
      );
    }
  }

  renderChildren(children, current) {
    if (children.length <= 0 || current >= children.length) {
      return;
    }

    let currentChild = children[current];

    return (
      <li>
        <Link to={`/person/${currentChild.uuid}`}>
          <SexIcon sex={currentChild.sex} />
          <PersonName name={currentChild.name}/>
        </Link>
        {children.length > current + 1 && (
          <ul>{this.renderChildren(children, current + 1)}</ul>
        )}
      </li>
    );
  }

  renderAncestor(ancestor) {
    // Note start from one to skip first item in child array
    return (
      <li>
        <Link to={`/person/${ancestor.uuid}`}>
          <SexIcon sex={ancestor.sex} />
          <PersonName name={ancestor.name}/>
        </Link>
        <ul>
          {this.renderChildren(ancestor.leftChildren, 1)}
          {this.renderChildren(ancestor.rightChildren, 1)}
        </ul>
      </li>
    );
  }

  renderAncestorSelection() {
    const { commonAncestors } = this.state;

    if (commonAncestors === null || commonAncestors.length <= 1) {
      return null;
    }

    const ancestorSelection = commonAncestors.map((ancestor, idx) => (
      <option key={ancestor.uuid} value={idx}>
        <PersonName name={ancestor.name}/>
      </option>
    ));

    return (
      <form className="ui big form">
        <h1>Forfaðir/formóðir:</h1>
        <select
          name="selectedAncestor"
          value={this.state.selectedAncestor}
          onChange={this.updateSelection}
        >
          {ancestorSelection}
        </select>
      </form>
    );
  }

  render() {
    const { commonAncestors, selectedAncestor } = this.state;
    const commonAncestor =
      selectedAncestor === -1 ? null : commonAncestors[selectedAncestor];

    return (
      <div className="ui segment tree" style={{ backgroundColor: "white" }}>
        {this.renderAncestorSelection()}
        <ul>
          {commonAncestor !== null && this.renderAncestor(commonAncestor)}
          {commonAncestor === null && (
            <div className="ui center aligned">
              <h2>Tenging fannst ekki</h2>
            </div>
          )}
        </ul>
        <div className="ui clearing hidden divider" />
      </div>
    );
  }
}

export default Ancestor;
