import React from "react";
import { NavLink as Link } from "react-router-dom";

import Genealogy from "./Genealogy";
import makeNodeTree from "./utils/renderTree";
import HorizontalTree from "./HorizontalTree";

class AncestorTree extends React.Component {
  state = {
    ancestorTree: null,
    loading: false
  };

  loadAncestorTree = uuid => {
    if (uuid !== "") {
      this.setState({ loading: true });
      Genealogy.getAncestorTree(uuid, ancestorTree => {
        this.setState({ ancestorTree: ancestorTree, loading: false });
      });
    } else {
      this.setState({ ancestorTree: null, loading: false });
    }
  };

  componentWillMount() {
    this.loadAncestorTree(this.props.match.params.uuid);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.uuid !== nextProps.match.params.uuid) {
      this.loadAncestorTree(nextProps.match.params.uuid);
    }
  }

  render() {
    const { ancestorTree } = this.state;

    let nodes = makeNodeTree(
      ancestorTree !== null ? [ancestorTree] : [],
      item => {
        return item.parents;
      }
    );

    return (
      <div className="ui segments">
        <div className="ui padded segment">
          <h1 className="ui header floated left">Framætt</h1>
          <Link
            to={`/person/${this.props.match.params.uuid}`}
            title="Til baka"
            className="ui icon basic button right floated"
          >
            <i className="angle left icon" />
            Til baka
          </Link>
          <HorizontalTree nodes={nodes} nodeWidth={120} nodeHeight={20} />
        </div>
      </div>
    );
  }
}

export default AncestorTree;
