import React from "react";
import { NavLink as Link } from "react-router-dom";
import PropTypes from "prop-types";

import SpouseTypes from "./utils/SpouseTypes";

import SexIcon from "./SexIcon";
import PersonName from "./PersonName";
import PersonSimple from "./PersonSimple";
import EventDate from "./EventDate";
import RelationshipEdit from "./RelationshipEdit";

class Spouse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false
    };

    this.startEdit = this.startEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.relationshipUpdated = this.relationshipUpdated.bind(this);
  }

  startEdit() {
    this.setState({ editing: true });
  }

  cancelEdit() {
    this.setState({ editing: false });
  }

  relationshipUpdated(relationshipInfo) {
    this.setState({ editing: false });
    this.props.relationshipUpdated(this.props.spouseIndex, relationshipInfo);
  }

  render() {
    const { editing } = this.state;
    const {
      person,
      spouse,
      selectedPeople,
      toggleSelection,
      worklist,
      togglePersonWorklist
    } = this.props;

    let type = spouse.uuid !== null ? SpouseTypes.getTypeLabel(spouse.unionType, spouse.sex) : "";

    if (spouse.split) {
      type += " / Skildu";
    }

    const childRows = spouse.children.map((child, idx) => (
      <PersonSimple
        person={child}
        selectedPeople={selectedPeople}
        toggleSelection={toggleSelection}
        worklist={worklist}
        togglePersonWorklist={togglePersonWorklist}
        detailed={true}
        key={idx}
        prefix={child.label + ") "}
      />
    ));

    return (
      <div className="ui segment">
        {editing && (
          <RelationshipEdit
            person={person}
            spouse={spouse}
            cancelEdit={this.cancelEdit}
            relationshipUpdated={this.relationshipUpdated}
          />
        )}
        {spouse.uuid && (
          <div className="item">
            <h4>
            <div className="ui tiny compact icon buttons right floated">
                <button
                  title="Afskrá maka"
                  className="ui basic icon button"
                  onClick={() => this.props.unlinkPersonAction(spouse, 'SPOUSE')}
                >
                  <i className="ban icon" />
                </button>
              </div>
              <button
                className="compact ui icon basic small button"
                onClick={this.startEdit}
                title="Uppfæra samband"
              >
                <i className="ui edit icon" />
              </button>
              <SexIcon sex={spouse.sex} />
              {selectedPeople && (
                <input
                  type="checkbox"
                  title="Velja einstakling"
                  onChange={event => {
                    toggleSelection(spouse.uuid);
                  }}
                  checked={!!selectedPeople[spouse.uuid]}
                />
              )}
              &nbsp;<Link to={`/person/${spouse.uuid}`}>
                <PersonName name={spouse.name}/>
              </Link>
              <EventDate
                eventDate={spouse.birth}
                render={date => (
                  <span className="ui tiny" style={{ fontSize: "12px" }}>
                    , f. {date}
                  </span>
                )}
              />
              <EventDate
                eventDate={spouse.death}
                render={date => (
                  <span className="ui tiny" style={{ fontSize: "12px" }}>
                    , d. {date}
                  </span>
                )}
              />
              <div
                className="ui right floated tiny header"
                style={{ fontSize: "12px" }}
              >
                <EventDate
                  eventDate={spouse.date}
                  render={date => <span>{date} - </span>}
                />
                {type}
              </div>
            </h4>
          </div>
        )}
        {spouse.children.length > 0 && (
          <div className="ui list">
            {spouse.uuid && <div className="ui clearing divider" />}
            {childRows}
          </div>
        )}
      </div>
    );
  }
}

Spouse.propTypes = {
  person: PropTypes.object.isRequired,
  spouse: PropTypes.object.isRequired,
  spouseIndex: PropTypes.number.isRequired,
  selectedPeople: PropTypes.object,
  toggleSelection: PropTypes.func,
  worklist: PropTypes.object.isRequired,
  togglePersonWorklist: PropTypes.func,
  relationshipUpdated: PropTypes.func.isRequired
};

export default Spouse;
