const dict = {
	is: {
		'Sign In': 'Innskrá',
		'Sign Up': 'Nýskrá',
		'Sign Out': 'Útskrá',
		'Sign in to your account': 'Skrá sig inn',
		Username: 'Notendanafn',
		Password: 'Lykilorð',
		'Enter your username': 'Sláðu inn notendanafn',
		'Enter your password': 'Sláðu inn lykilorð',
		'No account? ': 'Vantar aðgang? ',
		'Forget your password? ': 'Gleymt lykilorð? ',
		'Reset password': 'Endurstilla lykilorð',
		'User does not exist': 'Notandi er ekki til',
		'User already exists': 'Notandi er nú þegar til',
		'Incorrect username or password':
			'Notendanafn eða lykilorð er rangt',
		'Invalid password format': 'Lykilorð er ekki á réttu formu',
        'Create account': 'Stofna aðgang',
		'Forgot Password': 'Gleymt lykilorð',
		'Change Password': 'Breyta lykilorð',
		'New Password': 'Nýtt lykilorð',
		Email: 'Netfang',
		'Phone Number': '´Sími',
		'Confirm a Code': 'Staðfesta kóða',
		'Confirm Sign In': 'Staðfesta innskráningu',
		'Confirm Sign Up': 'Staðfesta skráninug',
		'Back to Sign In': 'Til baka í innskráningu',
		'Send Code': 'Senda kóða',
		Confirm: 'Staðfesta',
		'Resend Code': 'Endursenda kóða',
		Submit: 'Staðfesta',
		Skip: 'Sleppa',
		Verify: 'Sannreyna',
		'Verify Contact': 'Sannreyna samskiptaupplýsingar',
		Code: 'Kóði',
		'Confirmation Code': 'Staðfestingarkóði',
		'Lost your code? ': 'Týndur kóði? ',
		'Account recovery requires verified contact information':
			'Endurheimt þarfnast staðfestra samskiptaupplýsinga',
		'Invalid phone number format': `Símanúmer ekki rétt formað.
           Settu inn númer með sniðinu: +354 XXXXXXX`,
		'Create Account': 'Stofna aðgang',
		'Have an account? ': 'Ertu með aðgang? ',
		'Sign in': 'Innskrá',
        'Create a new account': 'Stofna aðgang',
		'Reset your password': 'Endurstilla lykilorð',
		'An account with the given email already exists.':
			'Notandi með þetta netfang er þegar til.',
		'Username cannot be empty': 'Notendanafn má ekki vera tómt',
		'Password attempts exceeded':
			'Of margar misheppnaðar innskráningatilraunir',
    }
};

export default dict;