import React from "react";
import PropTypes from "prop-types";

const PersonName = ({ name }) => {
  name = "" + name;
  
  const markerPosition = name.indexOf("!");
  let primaryNamePart = null;

  if (markerPosition !== -1) {
    primaryNamePart = name.substring(markerPosition).replace(/!/g, "").trim();
    name = name.substring(0,markerPosition);
  }
  
  name = name.replace(/!/g, "");

  if (primaryNamePart !== null && primaryNamePart !== "") {
    return (
      <React.Fragment>
        <span style={{fontWeight: 100}}>{name}</span>
        {primaryNamePart}
      </React.Fragment>
    );
  } else {
    return name;
  }
};

PersonName.propTypes = {
  name: PropTypes.string.isRequired
};

export default PersonName;
