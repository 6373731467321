function getBaseURI() {
  return "https://k100alu3nb.execute-api.eu-central-1.amazonaws.com/api";
  //return 'http://localhost:8000';
}

function getDisconnected(skipItems, datePeriod, callBack) {
  return fetch(`${getBaseURI()}/disconnected`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "content-type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({ skip: skipItems, date: datePeriod })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getDuplicates(skipItems, datePeriod, callBack) {
  return fetch(`${getBaseURI()}/duplicates`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "content-type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({ skip: skipItems, date: datePeriod })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function updateReviews(personUUIDS, callBack) {  
  return fetch(`${getBaseURI()}/review`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify(personUUIDS)
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}


function getPlaces(parentUUID, callBack) {
  return fetch(`${getBaseURI()}/places/${parentUUID ? parentUUID : ''}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getPlaceInfo(placeUUID, callBack) {
  return fetch(`${getBaseURI()}/place/${placeUUID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}


function getPerson(id, callBack) {
  return fetch(`${getBaseURI()}/person/${id}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getPersonDifferencesForCombine(personUUID, otherPersonUUID, callBack) {
  return fetch(`${getBaseURI()}/combine/${personUUID}/${otherPersonUUID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function combinePersons(personUUID, otherPersonUUID, selections, callBack) {  
  return fetch(`${getBaseURI()}/combine/${personUUID}/${otherPersonUUID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify(selections)
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function updateRelationship(
  personUUID,
  spouseUUID,
  relationshipInfo,
  callBack
) {
  return fetch(`${getBaseURI()}/relationship/${personUUID}/${spouseUUID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify(relationshipInfo)
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function updatePerson(id, personDetails, callBack) {
  return fetch(`${getBaseURI()}/person/${id}/edit`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify(personDetails)
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function linkPerson(personUUID, linkType, linkInfo, callBack) {
  return fetch(`${getBaseURI()}/person/${personUUID}/link/${linkType}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify(linkInfo)
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}


function unlinkPerson(personUUID, otherPersonUUID, linkType, callBack) {
  return fetch(`${getBaseURI()}/person/${personUUID}/unlink/${otherPersonUUID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({'type': ("" + linkType).toUpperCase()})
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getStatistics(callBack) {
  return fetch(`${getBaseURI()}/statistics`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "content-type": "application/json"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getConnectivityStatistics(callBack) {
  return fetch(`${getBaseURI()}/statistics/connectivity`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "content-type": "application/json"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function searchPerson(term, date, callBack) {
  return fetch(`${getBaseURI()}/person/search`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "content-type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({ term: term, date: date })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function searchPlace(term, callBack) {
  return fetch(`${getBaseURI()}/place/search`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "content-type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({ term: term })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getLinkCandidates(personUUID, linkType, name, birth, callBack) {
  return fetch(
    `${getBaseURI()}/person/${personUUID}/link-candidates/${linkType}`,
    {
      accept: "application/json",
      headers: new Headers({
        "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
        "content-type": "application/json"
      }),
      method: "POST",
      body: JSON.stringify({ name: name, birth: birth })
    }
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getFamilyTree(id, callBack) {
  return fetch(`${getBaseURI()}/person/${id}/descendants`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getAncestorTree(id, callBack) {
  return fetch(`${getBaseURI()}/person/${id}/ancestors`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function setPersonMark(id, marked, callBack) {
  return fetch(`${getBaseURI()}/person/${id}/mark`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({ marked: marked })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function setIgnoreInStats(id, ignore, callBack) {
  return fetch(`${getBaseURI()}/person/${id}/ignore-stats`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({ ignore: ignore })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function getCommonAncestor(person1, person2, callBack) {
  return fetch(`${getBaseURI()}/person/${person1}/common-ancestor/${person2}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1"
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function registerHabitation(personUUID, placeUUID, period, callBack) {
  return fetch(`${getBaseURI()}/habitat/`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({
      personUUID,
      placeUUID,
      period
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function updateHabitation(habitatID, placeUUID, period, callBack) {
  return fetch(`${getBaseURI()}/habitat/${habitatID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "POST",
    body: JSON.stringify({
      placeUUID,
      period
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(callBack);
}

function unregisterHabitation(habitatID, callBack) {
  return fetch(`${getBaseURI()}/habitat/${habitatID}`, {
    accept: "application/json",
    headers: new Headers({
      "x-api-key": "3ghRwAYLYV2LBnjUL0Mrf70o80kyjUNaAezufPV1",
      "Content-Type": "application/json"
    }),
    method: "DELETE"
  })
    .then(checkStatus)
    .then(callBack);
}


function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const responseJSON = response.json();
  const error = new Error(`HTTP Error ${responseJSON.Message}`);
  error.status = responseJSON.Message;
  error.response = response;
  console.log(error); // eslint-disable-line no-console
  throw error;
}

function parseJSON(response) {
  return response.json();
}

const Genealogy = {
  getPerson,
  updateRelationship,
  getPersonDifferencesForCombine,
  combinePersons,
  updatePerson,
  linkPerson,
  unlinkPerson,
  getStatistics,
  getConnectivityStatistics,
  searchPerson,
  searchPlace,
  getLinkCandidates,
  getFamilyTree,
  getCommonAncestor,
  getAncestorTree,
  setPersonMark,
  setIgnoreInStats,
  registerHabitation,
  updateHabitation,
  unregisterHabitation
};

export default Genealogy;
export {
  getPerson,
  updateRelationship,
  getPersonDifferencesForCombine,
  combinePersons,
  updatePerson,
  linkPerson,
  unlinkPerson,
  getStatistics,
  getConnectivityStatistics,
  searchPerson,
  searchPlace,
  getLinkCandidates,
  getFamilyTree,
  getCommonAncestor,
  getAncestorTree,
  setPersonMark,
  setIgnoreInStats,
  registerHabitation,
  updateHabitation,
  unregisterHabitation,
  getPlaces,
  getPlaceInfo,
  getDuplicates,
  updateReviews,
  getDisconnected
};
