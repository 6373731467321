import React, { useState } from "react";
import PropTypes from "prop-types";

import HabitatEditor from './HabitatEditor';
import Genealogy from "./Genealogy";

const PersonHabitation = ( {personUUID, habitation, habitationUpdatedAction}) => {

  const [editingHabitat, setEditingHabitat] = useState(null);
  const [addingHabitat, setAddingHabitat] = useState(false);

  const removeHabitat = (habitat) => {
    Genealogy.unregisterHabitation(habitat.id, result => {
      habitationUpdatedAction({ ...habitat, removed: true});
    });
  };

  const habitationRows = habitation.map((habitation, idx) => (
      <tr key={idx}>
        <td className="collapsing">{habitation.period}</td>
        <td>
          {habitation.placeName}
        </td>  
        <td style={{"textAlign": "right"}}>
          <div className="ui tiny compact icon buttons">
            <button className="ui basic icon button" title="Breyta ábúð" type="button" onClick={() => setEditingHabitat(habitation)}><i className="edit icon" /></button>
            <button className="ui basic icon button" title="Eyða ábúð" type="button" onClick={() => { if (window.confirm('Ertu viss um að þú viljir eyða ábúð?')) removeHabitat(habitation); }}><i className="delete icon" /></button>
          </div>
        </td>
      </tr>
  ));

  return (
      <div className="ui segment">
        {addingHabitat && <HabitatEditor personUUID={personUUID} updatedAction={ (newHabitat) => { habitationUpdatedAction(newHabitat);  setAddingHabitat(false); } } cancelAction={() => setAddingHabitat(false)}/>}
        {editingHabitat !== null && <HabitatEditor personUUID={personUUID} habitat={editingHabitat} updatedAction={ (updatedHabitat) => { habitationUpdatedAction(updatedHabitat);  setEditingHabitat(null)} } cancelAction={() => setEditingHabitat(null)}/>}
        <button
            title="Skrá ábúð"
            className="ui compact basic icon button right floated"
            onClick={() => setAddingHabitat(true) }
          >
            <i className="add icon" />
            Skrá ábúð
          </button>
        <h5 className="ui header left floated">
          <i className="home icon"/> Ábúð 
        </h5>
        <table className="ui very basic table">
          <tbody>
            {habitationRows}              
          </tbody>
        </table>
      </div>
  );
}

PersonHabitation.propTypes = {
  habitation: PropTypes.array.isRequired,
  habitationUpdatedAction: PropTypes.func.isRequired
};

export default PersonHabitation;