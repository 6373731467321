const monthsShort = ["jan.", "febr.", "mars", "apríl", "maí", "júní", "júlí", "ágúst", "sept.", "okt.", "nóv.", "des."];
const monthAbbr = ["jan", "feb", "mar", "apr", "maí", "jún", "júl", "ágú", "sep", "okt", "nóv", "des"];
const monthAbbrExtended = ["jan", "feb", "mar", "apr", "maí", "jún", "júl", "ágú", "sep", "okt", "nóv", "des", "febr", "ág", "sept", "janúar", "febrúar", "mars", "apríl", "maí", "júní", "júlí", "ágúst", "september", "október", "nóvember", "desember"];



function convertDateForAPI(dateStr, allowExtras) {
  if (dateStr === null) {
    dateStr = "";
  }

  dateStr = dateStr.trim();
  let extrasText = null;

  if (allowExtras === true) {
    const extrasRegex = new RegExp(
      "(?:\\(([^\\)]*)\\))",
      "ig"
    );

    const extrasMatch = dateStr.match(extrasRegex);
    if (extrasMatch !== null) {
      extrasText = extrasMatch.join(" ");
      dateStr = dateStr.replace(extrasRegex, "").trim();
    }
  }

  const months = monthAbbrExtended.join("|");
  const monthRegex = new RegExp(
    "(?:(?:^|\\s)(" + months + ")(?:\\s*-\\s*(" + months + "))?)[\\s\\.]",
    "i"
  );

  let monthMatch = monthRegex.exec(dateStr);

  if (monthMatch !== null) {
    const monthNumber = monthAbbr.indexOf(monthMatch[1].substring(0, 3));
    const monthNumber2 = monthMatch[2]
      ? monthAbbr.indexOf(monthMatch[2].substring(0, 3))
      : null;

    if (monthNumber2 !== null) {
      dateStr = dateStr.replace(
        monthRegex,
        `${monthNumber + 1}.-${monthNumber2 + 1}. `
      );
    } else {
      dateStr = dateStr.replace(monthRegex, `${monthNumber + 1}. `);
    }

    monthMatch = monthRegex.exec(dateStr);
  }

  const dateRegex = new RegExp(
    "(?:^|[^0-9]\\s+)(?:(?:(?:(?:([0-9]{1,2})\\.?\\s*-\\s*)?(?:([0-9]{1,2})[\\.\\s]+))?(?:(?:([0-9]{1,2})\\.?\\s*-\\s*)?([0-9]{1,2})[\\.\\s]+))|(um|fyrir|eftir)\\s+)?(?:([0-9]{3,4})|\\(([0-9]{3,4})\\))$",
    "i"
  );

  let dateMatch = dateRegex.exec(dateStr);

  let otherText = dateStr;
  let dateInfo = {
    date: null
  };

  if (dateMatch !== null) {
    let matchEnd = dateMatch.index + dateMatch[0].length;
    otherText =
      (dateMatch.index > 0 ? dateStr.substring(0, dateMatch.index + 1) : "") +
      (matchEnd < dateStr.length ? dateStr.substring(matchEnd) : "");

    if (dateMatch[7]) {
      // Date matches (YYYY) => YEAR ± 50
      dateInfo.date = `${parseInt(dateMatch[7], 10) - 50}-01-01`;
      dateInfo.dateTo = `${parseInt(dateMatch[7], 10) + 50}-12-31`;
    } else if (dateMatch[5] && dateMatch[6]) {
      const uncertaintyType = dateMatch[5].toLowerCase();
      
      if (uncertaintyType === "um") {
        // um YYYY => YEAR ± 10
        dateInfo.date = `${parseInt(dateMatch[6], 10) - 10}-01-01`;
        dateInfo.dateTo = `${parseInt(dateMatch[6], 10) + 10}-12-31`;
      } else if (uncertaintyType === "fyrir") {
        // fyrir YYYY => <YEAR
        dateInfo.date = `0001-01-01`;
        dateInfo.dateTo = `${parseInt(dateMatch[6], 10) - 1}-12-31`;
      } else {
        // eftir YYYY => >YEAR
        dateInfo.date = `${parseInt(dateMatch[6], 10) + 1}-01-01`;
        dateInfo.dateTo = `9999-12-31`;
      }
    } else if (dateMatch[2]) {
      // [DD | D1-D2] MM YYYY => DD MM YYYY | D1 MM YYYY - D2 MM YYYY
      dateInfo.date = `${dateMatch[6]}-${dateMatch[4].padStart(
        2,
        "0"
      )}-${dateMatch[2].padStart(2, "0")}`;

      // D1-D2 MM YYYY => D1 MM YYYY - D2 MM YYYY
      if (dateMatch[1]) {
        dateInfo.date = `${dateMatch[6]}-${dateMatch[4].padStart(
          2,
          "0"
        )}-${dateMatch[1].padStart(2, "0")}`;
        dateInfo.dateTo = `${dateMatch[6]}-${dateMatch[4].padStart(
          2,
          "0"
        )}-${dateMatch[2].padStart(2, "0")}`;
      }
    } else if (dateMatch[4]) {
      // [MM | M1-M2] YYYY =>  MM YYYY | M1 YYYY - M2 YYYY
      let lastDayOfToMonth =
        "" + new Date(dateMatch[6], parseInt(dateMatch[4], 10), 0).getDate();

      // M1-M2 YYYY => M1 YYYY - M2 YYYY
      if (dateMatch[3]) {
        dateInfo.date = `${dateMatch[6]}-${dateMatch[3].padStart(2, "0")}-01`;
        dateInfo.dateTo = `${dateMatch[6]}-${dateMatch[4].padStart(
          2,
          "0"
        )}-${lastDayOfToMonth.padStart(2, "0")}`;
      } else {
        // MM YYYY => 01 MM YYYY - [28|30|31] MM YYYY
        dateInfo.date = `${dateMatch[6]}-${dateMatch[4].padStart(2, "0")}-01`;
        dateInfo.dateTo = `${dateMatch[6]}-${dateMatch[4].padStart(
          2,
          "0"
        )}-${lastDayOfToMonth.padStart(2, "0")}`;
      }
    } else if (dateMatch[6]) {
      // YYYY => 01 01 YYYY - 31 12 YYYY
      dateInfo.date = `${dateMatch[6]}-01-01`;
      dateInfo.dateTo = `${dateMatch[6]}-12-31`;
    } else {
      dateInfo = null;
    }
  }

  if (allowExtras === true && extrasText !== null && dateInfo !== null ) {
    dateInfo.extras = extrasText;
  }

  return {
    date: dateInfo.date === null ? null : dateInfo,
    otherText: otherText
  };
}

function validateHabitationPeriod(period) {
  const periodRegex = new RegExp("^(-)?([0-9]{3,4})(?:-([0-9]{3,4}))?(-)?$");

  const periodMatch = periodRegex.exec(("" + period).trim())

  if (periodMatch === null) {
    return false;
  }

  let periodInfo = {
    date: periodMatch["2"],
    dateTo: periodMatch["3"] || null,
    startYear: parseInt(periodMatch["2"], 10),
    startUncertain: periodMatch["1"] === '-',
    endUncertain: periodMatch["4"] === '-'
  };

  let periodStart = parseInt(periodInfo.date, 10);
  let periodEnd = periodInfo.dateTo && parseInt(periodInfo.dateTo, 10);

  if (periodEnd && (periodEnd < periodStart)) {
    return false;
  }

  if (!periodEnd) {
    periodInfo.dateTo = periodInfo.date;
  }

  periodInfo.date = "" + periodInfo.date + '-01-01';

  if (periodInfo.dateTo) {
    periodInfo.dateTo = "" + periodInfo.dateTo + '-12-31';
  }

  return periodInfo;
}

function parseDBDate(dbDate) {
  const dateParts = dbDate.trim().split("-");

  const parsedDate = {
    year: parseInt(dateParts[0], 10),
    month: parseInt(dateParts[1], 10) - 1,
    day: parseInt(dateParts[2], 10),
  };

  return parsedDate;
}

function formatDate(date) {
  return `${date.day}. ${monthsShort[date.month]} ${date.year}`;
}

function parseEventDate(eventDate) {
  if (!eventDate || !eventDate["date"]) {
    return null;
  }

  const date = parseDBDate(eventDate["date"]);
  let dateStr = formatDate(date);

  if (eventDate["dateTo"]) {
    const dateTo = parseDBDate(eventDate["dateTo"]);
    const yearDiff = dateTo.year - date.year;
    const monthDiff = dateTo.month - date.month;
    const lastDayOfToMonth = new Date(dateTo.year, dateTo.month+1, 0);

    if (date.day === 1 && date.month === 0 && date.year === 1) {
      dateStr = `fyrir ${dateTo.year+1}`;
    }
    else if (dateTo.year === 9999) {
      dateStr = `eftir ${date.year-1}`;
    }
    else if (
      date.day === 1 &&
      date.month === 0 &&
      dateTo.day === 31 &&
      dateTo.month === 11 &&
      (yearDiff === 100 || yearDiff === 20)
    ) {
      dateStr =
        yearDiff === 100 ? `(${date.year + 50})` : `um ${date.year + 10}`;
    } else if (
      yearDiff === 0 &&
      monthDiff === 11 &&
      date.day === 1 &&
      dateTo.day === 31
    ) {
      dateStr = `${dateTo.year}`;
    } else if (
      yearDiff === 0 &&
      monthDiff === 0 &&
      date.day === 1 &&
      dateTo.day === lastDayOfToMonth.getDate()
    ) {
      dateStr = `${monthsShort[dateTo.month]} ${dateTo.year}`;
    } else if (
      yearDiff === 0 &&
      monthDiff === 0 &&
      date.day === 1 &&
      dateTo.day !== lastDayOfToMonth.getDate()
    ) {
      dateStr = `${date.day}-${dateTo.day} ${monthsShort[dateTo.month]} ${dateTo.year}`;
    } else {
      dateStr = `${formatDate(date)} til ${formatDate(dateTo)}`;
    }
  }

  return dateStr;
}

const Dates = { convertDateForAPI, parseEventDate, validateHabitationPeriod };
export default Dates;